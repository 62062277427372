import React from 'react';
import { Helmet } from 'react-helmet-async';

import { MAIN_TITLE } from '../../assets/styles/variables';
import LoginForm from './components/LoginForm';

const Login = () => (
  <>
    <Helmet>
      <title>Login | {MAIN_TITLE}</title>
    </Helmet>
    <LoginForm />
  </>
);

export default Login
