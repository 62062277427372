import React from 'react';
import { Helmet } from 'react-helmet-async';

import { MAIN_TITLE } from '../../assets/styles/variables';
import ForgottenPasswordForm from './components/ForgottenPasswordForm';

const ForgottenPassword = () => (
  <>
    <Helmet>
      <title>Forgotten password | {MAIN_TITLE}</title>
    </Helmet>
    <ForgottenPasswordForm />
  </>
);

export default ForgottenPassword
