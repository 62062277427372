import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { COLOR_BACKGROUND } from '../../../../assets/styles/variables';
import { getDateFormValue } from '../../../../shared/helpers';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: COLOR_BACKGROUND,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ShippingDetails = ({
  placeOfDelivery,
  containerName,
  containerShippingLine,
  terminal,
  expectedDate,
  shipperLink,
  plateNumber,
  truckCompany,
  containerExpectedDate
}) => (
  <TableContainer component={Paper}>
    <Table aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell>Shipping details</StyledTableCell>
          <StyledTableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row">
            Port of destination
          </StyledTableCell>
          <StyledTableCell>
            {placeOfDelivery || '-'}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row">
            Container number
          </StyledTableCell>
          <StyledTableCell>
            {containerName || '-'}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row">
            Container shipping line
          </StyledTableCell>
          <StyledTableCell>
            {
              (containerShippingLine && containerShippingLine.name && containerName)
                ? (
                  <p>
                    <a
                      href={containerShippingLine.trackUrl.replace('{#id}', containerName)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {containerShippingLine.name}
                    </a>
                  </p>
                )
                : containerShippingLine.name || '-'
            }
          </StyledTableCell>
        </StyledTableRow><StyledTableRow>
        <StyledTableCell component="th" scope="row">
          Truck's plate number
        </StyledTableCell>
        <StyledTableCell>
          {plateNumber || '-'}
        </StyledTableCell>
      </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row">
            Truck company
          </StyledTableCell>
          <StyledTableCell>
            {
              (truckCompany && truckCompany.name && plateNumber)
                ? (
                  <p>
                    <a
                      href={truckCompany.trackUrl.replace('{#id}', plateNumber)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {truckCompany.name}
                    </a>
                  </p>
                )
                : truckCompany.name || '-'
            }
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row">
            Loading terminal
          </StyledTableCell>
          <StyledTableCell>
            {terminal || '-'}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row">
            ETA
          </StyledTableCell>
          <StyledTableCell>
            {expectedDate || containerExpectedDate || '-'}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row">
            Shipper link
          </StyledTableCell>
          <StyledTableCell>
            {shipperLink ? (
              <a
                href={shipperLink.includes('http') ? shipperLink : `http://${shipperLink}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {shipperLink}
              </a>
            ) : '-'}
          </StyledTableCell>
        </StyledTableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export default ShippingDetails;
