import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar, Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';

import Loader from '../../../components/Loader';
import { post } from '../../../shared/api';
import { getErrorMessage } from '../../../shared/helpers';

import styles from './ForgottenPasswordForm.module.scss'

const ForgottenPasswordForm = () => {
  const { control, handleSubmit } = useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [isMailSent, setIsMailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setErrorMessage('');
    setIsLoading(true);

    await post(`/identity/forgetPassword?email=${data.email}`)
      .then((response) => {
        if (response.success) {
          setIsMailSent(true);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          setErrorMessage('Please, provide a valid email address!');
        } else {
          setErrorMessage(getErrorMessage(error));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container component="main" maxWidth="sm" className={styles.authCard}>
      <CssBaseline />
      <div className={styles.wrapper}>
        <div className={styles.accentImage} />
        <div className={styles.headerWrapper}>
          <div className={styles.avatarWrapper}>
            <Avatar className={styles.avatar}>
              <LockOutlined />
            </Avatar>
          </div>
          <Typography className={styles.headerText} component="h1" variant="h5">
            Forgotten password
          </Typography>
        </div>
        {
          !isMailSent ? (
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Email"
                        variant="outlined"
                        required
                        fullWidth
                        autoComplete="email"
                      />
                    )}
                  />
                </Grid>
                {errorMessage && (
                  <Grid item xs={12}>
                    <Typography color="error" variant="body2">
                      {errorMessage}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={styles.submitButton}
              >
                Send recovery email
              </Button>
            </form>
          ) : (
            <Typography variant="body1">
              Check you email for a recover link!
              <br />
              <br />
            </Typography>
          )
        }
        <Grid container className={styles.additionalActions}>
          <Grid item xs={12} className={styles.registerAction}>
            Don't have an account?
            &nbsp;
            <Link
              component={RouterLink}
              to="/register"
              style={{ textDecoration: 'none' }}
            >
              Sign up
            </Link>
          </Grid>
        </Grid>
      </div>
      {isLoading && <Loader />}
    </Container>
  );
};

export default ForgottenPasswordForm;
