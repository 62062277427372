import React from 'react';
import { Helmet } from 'react-helmet-async';

import { MAIN_TITLE } from '../../assets/styles/variables';
import RegisterForm from './components/RegisterForm';

const Register = () => (
  <>
    <Helmet>
      <title>Register | {MAIN_TITLE}</title>
    </Helmet>
    <RegisterForm />
  </>
);

export default Register
