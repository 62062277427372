import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add, AttachFile, Delete, Edit } from '@material-ui/icons';

import { MAIN_TITLE } from '../../../../assets/styles/variables';
import Loader from '../../../../components/Loader';
import Table from '../../../../components/Table';
import TransferList from '../../../../components/TransferList';
import { del, get, post, put } from '../../../../shared/api';
import { TRUCK_STATUSES, ROLE_ADMIN, ROLE_WAREHOUSE } from '../../../../shared/contants';
import { getDateFormValue, getErrorMessage } from '../../../../shared/helpers';
import { selectUser } from '../../../../store/selectors/auth.selectors';

import styles from '../../../Home/Home.module.scss';
import { Link as RouterLink } from 'react-router-dom';

const TrucksList = () => {
  const [allTrucks, setAllTrucks] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [truckCompanies, setTruckCompanies] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [truckToEdit, setTruckToEdit] = useState(null);
  const [truckToDelete, setTruckToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [areVehiclesLoading, setAreVehiclesLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');
  const [searchFilter, setSearchFilter] = useState('');
  const [currentTruckLoadedVehicles, setCurrentTruckLoadedVehicles] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const currentUser = useSelector(selectUser);
  const { addToast } = useToasts();
  const { control, handleSubmit, reset } = useForm();

  const setFilteredOrders = (status, searchTerm) => {
    let filteredOrders = [...allTrucks];

    if (typeof status === 'number' && status !== 'all') {
      filteredOrders = filteredOrders.filter((truck) => truck?.status === status);
    }

    if (searchTerm && searchTerm.trim()) {
      filteredOrders = filteredOrders.filter((truck) => {
        return JSON.stringify(Object.values(truck)).toLowerCase().includes(searchTerm.toLowerCase());
      });
    }

    setTrucks(filteredOrders);
  };

  const loadTrucks = async () => {
    setIsLoading(true);

    await get('/truck-companies')
      .then((data) => {
        if (Array.isArray(data)) {
          data.map(truckData => ({
            ...truckData,
            expectedDate: getDateFormValue(data.expectedDate),
          }))

          setTruckCompanies(data);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });

    let ordersUrl = '/trucks/my';
    if (currentUser.role === ROLE_ADMIN) {
      ordersUrl = '/trucks';
    }

    await get(ordersUrl)
      .then((trucksData) => {
        if (Array.isArray(trucksData)) {
          setAllTrucks(trucksData);
          setTrucks(trucksData);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const loadVehicles = () => {
    setAreVehiclesLoading(true);

    let vehiclesUrl = '/vehicles/my';
    if (currentUser.role === ROLE_ADMIN) {
      vehiclesUrl = '/vehicles/container';
    } else if (currentUser.role === ROLE_WAREHOUSE) {
      vehiclesUrl = `/vehicles/warehouse/${currentUser.email}`;
    }

    get(vehiclesUrl)
      .then((vehiclesData) => {
        if (Array.isArray(vehiclesData)) {
          setVehicles(vehiclesData);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setAreVehiclesLoading(false);
      });
  }

  useEffect(() => {
    loadTrucks();
    loadVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allTrucks.length > 0) {
      setFilteredOrders(statusFilter, searchFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, searchFilter]);

  const openEditDialog = (truckData) => {
    console.log(truckData.id);

    setCurrentTruckLoadedVehicles(truckData.vehicleIds);
    setTruckToEdit(truckData);
    reset({
        ...truckData,
        truckCompany: truckData?.truckCompany?.id || '',
        status: TRUCK_STATUSES.find(status => status.value === truckData?.status)?.value,
      },
    );
  };

  const closeEditDialog = () => {
    setCurrentFile(null);
    setCurrentTruckLoadedVehicles(null);
    setTruckToEdit(null);
    reset({
      plateNumber: '',
      expectedDate: '',
      truckCompany: '',
      status: '',
      notes: '',
    });
  };

  const editTruck = async (data) => {
    setIsEditLoading(true);

    const {
      id,
      expectedDate,
      plateNumber,
      truckCompany,
      status,
      notes,
    } = data;

    let formData = {
      plateNumber,
      status,
      vehicleIds: currentTruckLoadedVehicles,
      username: currentUser.email,
      notes,
    };

    if (truckCompany) {
      formData = {
        ...formData,
        truckCompanyId: truckCompany,
      };
    }

    if (expectedDate) {
      formData = {
        ...formData,
        expectedDate,
      };
    }

    if (id) {
      await put(`/trucks/${id}`, formData)
        .then((response) => {
          if (response) {
            setErrorMessage('');
            addToast(
              'Truck updated successfully!',
              { appearance: 'success' }
            );

            loadTrucks();
            loadVehicles();
            setSearchFilter('');
            setStatusFilter('all');
            closeEditDialog();
          }
        })
        .catch((error) => {
          setErrorMessage(getErrorMessage(error));
        })
        .finally(() => {
          setIsEditLoading(false);
        });
    } else {
      await post(`/trucks`, formData)
        .then((response) => {
          if (response) {
            setErrorMessage('');
            addToast(
              'Truck created successfully!',
              { appearance: 'success' }
            );

            if (currentFile) {
              let fileUploadFormData = new FormData();
              fileUploadFormData.append('files', currentFile);
              fileUploadFormData.append('typeOfFile', 'CMR');

              for (let index = 0; index < formData.vehicleIds?.length; index++) {
                fileUploadFormData.append('vehicleIds[]', formData.vehicleIds[index]);
              }

              post(
                `/pdf/upload/multiple`,
                fileUploadFormData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
              )
                .then((response) => {
                  if (response) {
                    setCurrentFile(null);
                    addToast('CMR successfully uploaded!', { appearance: 'success' });

                    loadTrucks();
                    loadVehicles();
                    closeEditDialog();
                    setSearchFilter('');
                    setStatusFilter('all');
                  }
                })
                .catch((error) => {
                  console.log(error);
                  addToast(getErrorMessage(error), { appearance: 'error' });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            } else {
              loadTrucks();
              loadVehicles();
              closeEditDialog();
              setSearchFilter('');
              setStatusFilter('all');
            }
          }
        })
        .catch((error) => {
          setErrorMessage(getErrorMessage(error));
        })
        .finally(() => {
          setIsEditLoading(false);
        });
    }
  };

  const setLoadedVehicles = (vehicleVins) => {
    setCurrentTruckLoadedVehicles(
      vehicles
        .filter(vehicle => vehicleVins.includes(vehicle.vin))
        .map(vehicle => vehicle.vehicleId)
    );
  };

  const deleteTruck = () => {
    setIsDeleteLoading(true);

    del(`/trucks/${truckToDelete.id}`)
      .then((response) => {
        if (response) {
          addToast('Truck deleted successfully!', { appearance: 'success' });
          setTruckToDelete(null);
          loadTrucks();
          loadVehicles();
          setSearchFilter('');
          setStatusFilter('all');
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsDeleteLoading(false);
      });
  };

  // File upload
  const selectFile = (event) => {
    setCurrentFile(event.target.files[0]);
  };

  return (
    <>
      <Helmet>
        <title>Trucks | {MAIN_TITLE}</title>
      </Helmet>
      <div className={styles.heading}>
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item xs={12} lg={2}>
            <Typography variant="h5">
              Trucks
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="status-filter" variant="outlined">Status</InputLabel>
              <Select
                value={statusFilter}
                onChange={(event) => setStatusFilter(event.target.value)}
                labelId="status-filter"
                label="Status"
                variant="outlined"
                fullWidth
              >
                <MenuItem value="all">
                  -- All --
                </MenuItem>
                {TRUCK_STATUSES.map(({ key, label, value }) => (
                  <MenuItem key={`status-${key}`} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={7} lg={6}>
            <TextField
              value={searchFilter}
              onChange={(event) => setSearchFilter(event.target.value)}
              label="Search"
              variant="outlined"
              placeholder="Search by plate number, truck company, etc..."
              fullWidth
              autoComplete="email"
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={2} lg={1}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={styles.createButton}
              onClick={() => {
                openEditDialog({
                  plateNumber: '',
                  truckCompany: '',
                  status: 0,
                  vehicleIds: [],
                });
              }}
            >
              <Add fontSize="small" />&nbsp;Create
            </Button>
          </Grid>
        </Grid>
      </div>
      {(isLoading || isEditLoading || isDeleteLoading || areVehiclesLoading) && <Loader />}
      <Table
        title=""
        columns={[
          {
            title: 'Status',
            field: 'status',
            searchable: false,
            render: truck => (
              <Chip
                label={TRUCK_STATUSES.find((status) => status.value === truck?.status)?.label || truck.status || '-'}
                color="primary"
              />
            )
          },
          { title: 'Plate number', field: 'plateNumber', render: truck => <p>{truck.plateNumber}</p> },
          {
            title: 'Truck company', field: 'truckCompany', render: truck => {
              return (truck.truckCompany && truck.truckCompany.trackUrl && truck.plateNumber)
                ? (
                  <p>
                    <a
                      href={truck.truckCompany.trackUrl.replace('{#id}', truck.plateNumber)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {truck.truckCompany.name}
                    </a>
                  </p>
                )
                : truck.truckCompany?.name || '-';
            },
          },
          {
            title: 'Expected date',
            field: 'expectedDate',
            render: truck => (
              <p>
                {
                  truck.expectedDate
                    ? new Date(getDateFormValue(truck.expectedDate)).toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  }) || '-'
                    : '-'
                }
              </p>
            ),
          },
          {
            title: 'Documents',
            field: 'filesResponse',
            sorting: false,
            searchable: false,
            render: order => order.cmr
              ? (
                <>
                  <span>CMR:&nbsp;</span>
                  <IconButton
                    component="a"
                    href={order.cmr?.url || order.cmr}
                    rel="noopener noreferrer"
                    target="_blank"
                    color="primary"
                    area-label="download"
                    size="small"
                    title={order.cmr?.title || order.cmr}
                  >
                    <AttachFile fontSize="small" />
                  </IconButton>
                </>
              ) : (
                <Typography color="error" variant="body1">
                  N/A
                </Typography>
              )
          },
          {
            align: 'center',
            title: 'Loaded vehicles',
            field: 'vehicleIds',
            render: truck => <p>{truck.vehicleIds?.length || 0}</p>
          },
          {
            align: 'right',
            searchable: false,
            sorting: false,
            title: '',
            render: truck => (
              <>
                <IconButton
                  to={`/trucks/${truck.id}/edit`}
                  component={RouterLink}
                  color="primary"
                  area-label="edit"
                >
                  <Edit />
                </IconButton>
                <IconButton
                  color="primary"
                  area-label="delete"
                  onClick={() => {
                    setTruckToDelete(truck)
                  }}
                >
                  <Delete />
                </IconButton>
              </>
            ),
          }
        ]}
        data={trucks}
        options={
          {
            search: false,
            paging: false,
            toolbar: false,
            maxBodyHeight: 'calc(100vh - 270px)',
          }
        }
      />
      <Dialog
        open={!!truckToEdit}
        onClose={closeEditDialog}
        fullWidth
        maxWidth="md"
      >
        <form onSubmit={handleSubmit(editTruck)}>
          <DialogTitle>{truckToEdit?.id ? 'Edit' : 'Create new'} truck</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                <Controller
                  name="plateNumber"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="Plate number"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="status" variant="outlined" required>Status</InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="status"
                        label="Status"
                        variant="outlined"
                        required
                        fullWidth
                      >
                        {TRUCK_STATUSES.map(({ key, label, value }) => (
                          <MenuItem key={`status-${key}`} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={truckToEdit && !truckToEdit.id ? 6 : 9}>
                <FormControl fullWidth size="small">
                  <InputLabel id="truckCompany" variant="outlined">Truck company</InputLabel>
                  <Controller
                    name="truckCompany"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="truckCompany"
                        label="Truck company"
                        variant="outlined"
                        fullWidth
                      >
                        <MenuItem key="truck-company-none" value="">-- Clear --</MenuItem>
                        {truckCompanies.map(({ id, name, trackUrl }) => (
                          <MenuItem key={`truck-company-${id}`} value={id}>
                            {name} ({trackUrl})
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  name="expectedDate"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="Expected date"
                      type="date"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              {truckToEdit && !truckToEdit.id && (
                <Grid item xs={12} md={3}>
                  <label htmlFor="btn-upload">
                    <input
                      id="btn-upload"
                      name="btn-upload"
                      style={{ display: 'none' }}
                      type="file"
                      onChange={selectFile}
                    />
                    <Button
                      className="btn-choose"
                      variant="outlined"
                      component="span"
                      fullWidth
                      style={{ minHeight: '37px' }}
                    >
                      {currentFile
                        ? (
                          <span style={{
                            width: '100%',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}>
                            CMR: {currentFile.name}
                          </span>
                        )
                        : 'Upload "CMR"'}
                    </Button>
                  </label>
                </Grid>
              )}
              {(truckToEdit && truckToEdit.vehicleIds && vehicles.length) ? (
                <Grid item xs={12}>
                  <TransferList
                    leftLabel="Vehicles"
                    rightLabel="Loaded vehicles"
                    initialLeftItems={vehicles.filter(v => !(v.truck && v.truck.plateNumber)).map(v => v.vin)}
                    initialRightItems={vehicles.filter(v => truckToEdit.vehicleIds.includes(v.id)).map(v => v.vin)}
                    handleChanged={setLoadedVehicles}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Controller
                  name="notes"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Notes"
                      variant="outlined"
                      multiline
                      rows={2}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              {errorMessage && (
                <Grid item xs={12}>
                  <Typography color="error" variant="body2">
                    {errorMessage}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button onClick={closeEditDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={!!truckToDelete}
        keepMounted
        onClose={() => setTruckToDelete(false)}
      >
        <DialogTitle>Delete truck</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this truck?
            {truckToDelete && (
              <>
                <br />
                Truck plate number: <strong>{truckToDelete.plateNumber}</strong>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteTruck} color="primary">
            Confirm
          </Button>
          <Button onClick={() => setTruckToDelete(null)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TrucksList;
