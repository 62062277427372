import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';

import { MAIN_TITLE } from '../../../../assets/styles/variables';
import Loader from '../../../../components/Loader';
import Table from '../../../../components/Table';
import { del, get, post, put } from '../../../../shared/api';
import { generateUrlElement, getErrorMessage } from '../../../../shared/helpers';

const AuctionNames = () => {
  const [items, setItems] = useState([]);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { addToast } = useToasts();
  const { control, handleSubmit, reset } = useForm();

  const loadItems = () => {
    setIsLoading(true);

    get('/auctions')
      .then((data) => {
        if (Array.isArray(data)) {
          setItems(data);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editItem = async (data) => {
    setIsActionLoading(true);

    if (data.id) {
      await put('/auctions', {
        ...data,
      })
        .then((response) => {
          if (response) {
            setErrorMessage('');
            addToast(
            'Auction name updated successfully!',
            { appearance: 'success' }
            );

            closeDialog();
            loadItems();
          }
        })
        .catch((error) => {
          setErrorMessage(getErrorMessage(error));
        })
        .finally(() => {
          setIsActionLoading(false);
        });
    } else {
      await post('/auctions', {
        ...data,
      })
        .then((response) => {
          if (response) {
            setErrorMessage('');
            addToast('Auction name created!', { appearance: 'success' });

            closeDialog();
            loadItems();
          }
        })
        .catch((error) => {
          setErrorMessage(getErrorMessage(error));
        })
        .finally(() => {
          setIsActionLoading(false);
        });
    }
  };

  const openEditItemDialog = (item) => {
    setItemToEdit(item);
    reset({ ...item });
  };

  const closeDialog = () => {
    setItemToEdit(null);
    setItemToDelete(null);
    reset({ name: '', trackUrl: '' });
  };

  const deleteItem = () => {
    if (!itemToDelete) {
      return;
    }

    setIsActionLoading(true);

    del(`/auctions/${itemToDelete.id}`)
      .then((response) => {
        if (response) {
          addToast('Auction name deleted successfully!', { appearance: 'success' });

          closeDialog();
          loadItems();
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsActionLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Auction names | {MAIN_TITLE}</title>
      </Helmet>
      <Grid container>
        <Grid item xs={12}>
          <Table
            title="Auction names"
            columns={[
              { title: 'Name', field: 'name', render: auction => auction.name || '-' },
              { title: 'Tracking URL', field: 'trackUrl', render: auction => generateUrlElement(auction?.trackUrl)},
              {
                align: 'right',
                cellStyle: {
                  width: 20,
                  maxWidth: 20
                },
                headerStyle: {
                  width: 20,
                  maxWidth: 20
                },
                searchable: false,
                sorting: false,
                render: item => (
                  <>
                    <IconButton
                      aria-label="edit"
                      type="button"
                      onClick={() => openEditItemDialog(item)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      type="button"
                      onClick={() => setItemToDelete(item)}
                    >
                      <Delete />
                    </IconButton>
                  </>
                ),
              },
            ]}
            data={items}
            options={{
              paging: false,
              maxBodyHeight: 'calc(100vh - 250px)',
            }}
            actions={[
              {
                icon: '+',
                tooltip: 'Add new',
                isFreeAction: true,
                onClick: () => setItemToEdit({ name: '', trackUrl: '' }),
              }
            ]}
          />
        </Grid>
      </Grid>
      <Dialog
        open={!!itemToEdit}
        onClose={closeDialog}
        fullWidth
        maxWidth="xs"
      >
        <form onSubmit={handleSubmit(editItem)}>
          <DialogTitle>{itemToEdit?.id ? `Edit "${itemToEdit?.name}"` : 'Create new'}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Auction name"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="trackUrl"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Tracking URL"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              {errorMessage && (
                <Grid item xs={12}>
                  <Typography color="error" variant="body2">
                    {errorMessage}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={!!itemToDelete}
        onClose={closeDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Delete confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {itemToDelete?.name ? itemToDelete?.name : 'this item'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteItem} color="primary">
            Confirm
          </Button>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {(isLoading || isActionLoading) && <Loader />}
    </>
  );
};

export default AuctionNames;
