export const INITIALIZE_USER = 'INITIALIZE_USER';
export const LOG_OUT_USER = 'LOG_OUT_USER';
export const SET_USER_FROM_TOKEN = 'SET_USER_FROM_TOKEN';

export const initializeUserAction = () => ({
  type: INITIALIZE_USER,
});

export const logOutUserAction = () => ({
  type: LOG_OUT_USER,
});

export const setUserFromTokenAction = (token) => ({
  type: SET_USER_FROM_TOKEN,
  payload: { token },
});
