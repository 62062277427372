import React from 'react';

import carLoader from '../../assets/images/loader copy.gif'
import styles from './Loader.module.scss';

const Loader = () => (
  <div className={styles.loaderBackdrop}>
    <div className={styles.loaderWrapper}>
      <img className={styles.loaderImage} src={carLoader} alt="Loading..." />
    </div>
  </div>
);

export default Loader;
