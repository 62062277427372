import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { MAIN_TITLE } from '../../assets/styles/variables';
import Layout from '../../components/Layout';
import PrivateRoute from '../../components/PrivateRoute';
import { initializeUserAction } from '../../store/actions/auth.actions';
import { selectIsUserAuthenticated } from '../../store/selectors/auth.selectors';
import Configuration from '../Configuration';
import Containers from '../Containers';
import ContainerForm from '../ContainerForm';
import ForgottenPassword from '../ForgottenPassword';
import Home from '../Home';
import Login from '../Login';
import NotFound from '../NotFound';
import OrderDetails from '../OrderDetails';
import OrderForm from '../OrderForm';
import Orders from '../Orders';
import Register from '../Register';
import ResetPassword from '../ResetPassword';
import Trucks from '../Trucks';
import TruckForm from '../TruckForm';
import UserProfile from '../UserProfile';
import Users from '../Users';
import VehicleRequest from '../VehicleRequest';

const LOGIN_ROUTE = '/login';

const App = () => {
  const dispatch = useDispatch();
  dispatch(initializeUserAction());

  const isAuthenticated = useSelector(selectIsUserAuthenticated);

  return (
    <>
      <Helmet>
        <title>Home | {MAIN_TITLE}</title>
      </Helmet>
      <Layout>
        <Switch>
          <PrivateRoute
            authenticationPath={LOGIN_ROUTE}
            component={Configuration}
            isAuthenticated={isAuthenticated}
            path="/configuration"
          />
          <PrivateRoute
            authenticationPath={LOGIN_ROUTE}
            component={UserProfile}
            exact
            isAuthenticated={isAuthenticated}
            path="/profile"
          />
          <PrivateRoute
            authenticationPath={LOGIN_ROUTE}
            component={ContainerForm}
            isAuthenticated={isAuthenticated}
            path="/containers/:id/edit"
          />
          <PrivateRoute
            authenticationPath={LOGIN_ROUTE}
            component={Containers}
            isAuthenticated={isAuthenticated}
            path="/containers"
          />
          <PrivateRoute
            authenticationPath={LOGIN_ROUTE}
            component={TruckForm}
            isAuthenticated={isAuthenticated}
            path="/trucks/:id/edit"
          />
          <PrivateRoute
            authenticationPath={LOGIN_ROUTE}
            component={Trucks}
            isAuthenticated={isAuthenticated}
            path="/trucks"
          />
          <PrivateRoute
            authenticationPath={LOGIN_ROUTE}
            component={Orders}
            exact
            isAuthenticated={isAuthenticated}
            path="/orders"
          />
          <PrivateRoute
            authenticationPath={LOGIN_ROUTE}
            component={OrderForm}
            exact
            isAuthenticated={isAuthenticated}
            path="/orders/create"
          />
          <PrivateRoute
            authenticationPath={LOGIN_ROUTE}
            component={VehicleRequest}
            exact
            isAuthenticated={isAuthenticated}
            path="/orders/request"
          />
          <PrivateRoute
            authenticationPath={LOGIN_ROUTE}
            component={OrderForm}
            exact
            isAuthenticated={isAuthenticated}
            path="/orders/:id/edit"
          />
          <PrivateRoute
            authenticationPath={LOGIN_ROUTE}
            component={Users}
            exact
            isAuthenticated={isAuthenticated}
            path="/users"
          />
          <Route path="/" exact component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/reset" component={ResetPassword} />
          <Route path="/forgotten" component={ForgottenPassword} />
          <Route path="/not-found" component={NotFound} />
          <Route path="/orders/:id" component={OrderDetails} exact />
          <Redirect from="*" to="/not-found" />
        </Switch>
      </Layout>
    </>
  );
}

export default App;
