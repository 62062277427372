import React, { useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ModifyNotificationsDialog from './ModifyNotificationsDialog';
import UserEditForm from './UserEditForm';
import { COLOR_BACKGROUND } from '../../../assets/styles/variables';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: COLOR_BACKGROUND,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const UserDetails = ({
  id,
  areNotificationsEnabled,
  email,
  firstName,
  lastName,
  phoneNumber,
  onDetailsUpdated,
}) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isModifyNotificationsDialogOpen, setIsModifyNotificationsDialogOpen] = useState(false);

  const openEditProfileDialog = () => {
    setIsEditDialogOpen(true);
  };

  const closeEditProfileDialog = () => {
    setIsEditDialogOpen(false);
  };

  const openModifyNotificationsDialog = () => {
    setIsModifyNotificationsDialogOpen(true);
  };

  const closeModifyNotificationsDialog = () => {
    setIsModifyNotificationsDialogOpen(false);
  };

  const profileDataUpdated = () => {
    onDetailsUpdated && onDetailsUpdated();
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan={2}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography component="span" variant="h6">
                    My profile
                  </Typography>
                  <Box textAlign="right">
                      <Button
                        onClick={openModifyNotificationsDialog}
                        style={{
                          backgroundColor: areNotificationsEnabled ? '#f59c9c' : '#86eab3'
                        }}
                        variant="contained"
                      >
                        {areNotificationsEnabled ? 'Disable' : 'Enable'} notifications
                      </Button>
                    &nbsp;
                    &nbsp;
                    <Button
                      onClick={openEditProfileDialog}
                      color="primary"
                      variant="contained"
                    >
                      Edit
                    </Button>
                  </Box>
                </div>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                First name
              </StyledTableCell>
              <StyledTableCell>
                {firstName || '-'}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Last name
              </StyledTableCell>
              <StyledTableCell>
                {lastName || '-'}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Email
              </StyledTableCell>
              <StyledTableCell>
                {email ? <a href={`mailto:${email}`}>{email}</a> : '-'}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                Phone number
              </StyledTableCell>
              <StyledTableCell>
                {phoneNumber ? <a href={`tel:${phoneNumber}`}>{phoneNumber}</a> : '-'}
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <UserEditForm
        isOpen={isEditDialogOpen}
        data={{
          id,
          email,
          firstName: firstName || '',
          lastName: lastName || '',
          phoneNumber: phoneNumber || '',
        }}
        onCancel={closeEditProfileDialog}
        onSuccess={profileDataUpdated}
      />
      <ModifyNotificationsDialog
        isOpen={isModifyNotificationsDialogOpen}
        userId={id}
        areNotificationsEnabled={areNotificationsEnabled}
        onCancel={closeModifyNotificationsDialog}
        onSuccess={profileDataUpdated}
      />
    </>
  );
};

export default UserDetails;
