import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';

import Loader from '../../../components/Loader';
import { put } from '../../../shared/api';
import { getErrorMessage } from '../../../shared/helpers';

const RoleEditForm = ({ isOpen, onSuccess, onCancel, data }) => {
  const { control, handleSubmit, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { addToast } = useToasts();

  useEffect(() => {
    reset(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const onFormSubmit = async (data) => {
    setIsLoading(true);
    const { id, role } = data;

    await put(`/identity/roles/${id}?role=${role}`, {})
      .then((response) => {
        if (response) {
          setErrorMessage('');
          addToast(
            'User role updated successfully!',
            { appearance: 'success' }
          );

          onSuccess && onSuccess();
        }
      })
      .catch((error) => {
        setErrorMessage(getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleCancel} fullWidth maxWidth="sm">
        <DialogTitle>Edit role for {data.email}</DialogTitle>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="role" variant="outlined" required>Role</InputLabel>
                  <Controller
                    name="role"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="role"
                        label="Role"
                        variant="outlined"
                        fullWidth
                        required
                      >
                        <MenuItem value="User">User</MenuItem>
                        <MenuItem value="Admin">Admin</MenuItem>
                        <MenuItem value="Warehouse">Warehouse</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              {errorMessage && (
                <Grid item xs={12}>
                  <Typography color="error" variant="body2">
                    {errorMessage}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <br />
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {isLoading && <Loader />}
    </>
  );
};

export default RoleEditForm;
