import React from 'react';
import { Helmet } from 'react-helmet-async';

import { MAIN_TITLE } from '../../assets/styles/variables';
import ResetPasswordForm from './components/ResetPasswordForm';

const ResetPassword = () => (
  <>
    <Helmet>
      <title>Password reset | {MAIN_TITLE}</title>
    </Helmet>
    <ResetPasswordForm />
  </>
);

export default ResetPassword
