import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

const NotFound = () => (
  <Card>
    <CardContent>
      <Typography color="error" variant="body2">
        Page not found!
      </Typography>
    </CardContent>
  </Card>
);

export default NotFound;
