import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useToasts } from 'react-toast-notifications';
import {
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { DeleteForever, Edit, Lock } from '@material-ui/icons';

import { MAIN_TITLE } from '../../assets/styles/variables';
import UserEditForm from '../UserProfile/components/UserEditForm';
import Loader from '../../components/Loader';
import Table from '../../components/Table';
import { del, get, post } from '../../shared/api';
import { getErrorMessage } from '../../shared/helpers';

import styles from './Users.module.scss';
import RoleEditForm from '../UserProfile/components/RoleEditForm';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userForDeleting, setUserForDeleting] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);
  const [roleToEdit, setRoleToEdit] = useState(null);
  const { addToast } = useToasts();

  const loadUsers = () => {
    setIsLoading(true);

    get('/identity/users/all')
      .then((usersData) => {
        setUsers(usersData);
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleUser = (userId, activate = true) => {
    if (!userId) {
      addToast('User ID is missing!', { appearance: 'error' });
      return;
    }

    post(`/identity/users/${activate ? '' : 'de'}activate/${userId}`, {})
      .then((response) => {
        addToast(response, { appearance: 'success' });
        loadUsers();
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });
  };

  const deleteUser = (event) => {
    event.preventDefault();
    setIsDeleteLoading(true);

    del(`/identity/delete/${userForDeleting?.id}`)
      .then((response) => {
        if (response) {
          setErrorMessage('');
          addToast('User successfully deleted!', { appearance: 'success' });
          setUserForDeleting(null);
          loadUsers();
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
        setErrorMessage(getErrorMessage(error));
      })
      .finally(() => {
        setIsDeleteLoading(false);
      });
  };

  const openEditProfileDialog = (userData) => {
    setUserToEdit({ ...userData });
  };

  const closeEditProfileDialog = () => {
    setUserToEdit(null);
  };

  const openEditRoleDialog = (userData) => {
    setRoleToEdit({ ...userData });
  };

  const closeEditRoleDialog = () => {
    setRoleToEdit(null);
  };

  const profileDataUpdated = () => {
    loadUsers();
    closeEditProfileDialog();
    closeEditRoleDialog();
  };

  return (
    <>
      <Helmet>
        <title>Users | {MAIN_TITLE}</title>
      </Helmet>
      <Container>
        <Table
          title="Users"
          columns={[
            {
              align: 'center',
              cellStyle: {
                width: 20,
                maxWidth: 20
              },
              headerStyle: {
                width: 20,
                maxWidth: 20
              },
              title: 'Status',
              field: 'isActive',
              searchable: false,
              render: user => (
                <Chip
                  className={user.isActive ? styles.active : styles.inactive}
                  label={user.isActive ? 'Active' : 'Inactive'}
                />
              )
            },
            {
              title: 'Name', field: 'firstName', render: user => {
                return `${user.firstName} ${user.lastName}` || '-'
              }
            },
            {
              title: 'Role',
              field: 'role',
              render: user => (
                user.role ? (
                  <Chip
                    className={styles[`role${user.role}`]}
                    label={user.role}
                  />
                ) : '-'
              )
            },
            {
              title: 'Email', field: 'email', render: user => {
                return user.email ? <a href={`mailto:${user.email}`}>{user.email}</a> : '-'
              }
            },
            {
              title: 'Phone number', field: 'phoneNumber', render: user => {
                return user.phoneNumber ? <a href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</a> : '-'
              }
            },
            {
              width: '205px',
              searchable: false,
              sortable: false,
              render: user => (
                <>
                  <Button
                    className={[styles.actionButton, user.isActive ? styles.deactivate : styles.activate].join(' ')}
                    onClick={() => toggleUser(user.id, !user.isActive)}
                  >
                    {user.isActive ? 'Deactivate' : 'Activate'}
                  </Button>
                  <br />
                  <IconButton
                    aria-label="edit"
                    type="button"
                    onClick={() => openEditProfileDialog(user)}
                  >
                    <Edit size="small" />
                  </IconButton>
                  &nbsp;
                  <IconButton
                    aria-label="role"
                    type="button"
                    onClick={() => openEditRoleDialog(user)}
                  >
                    <Lock />
                  </IconButton>
                  &nbsp;
                  <IconButton
                    aria-label="cards"
                    onClick={() => {
                      setErrorMessage('');
                      setUserForDeleting(user);
                    }}
                  >
                    <DeleteForever />
                  </IconButton>
                </>
              ),
            }
          ]}
          data={users}
          options={{
            paging: false,
            maxBodyHeight: 'calc(100vh - 190px)',
          }}
        />
        <br />
        <Dialog
          open={!!userForDeleting}
          keepMounted
          onClose={() => setUserForDeleting(null)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle>Delete user</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {errorMessage ? (
                <Typography color="error" variant="body2">
                  {errorMessage}
                </Typography>
              ) : (
                <>
                  Are you sure you want to delete
                  {
                    userForDeleting
                      ? ` ${userForDeleting.firstName} ${userForDeleting.lastName} (${userForDeleting.email})`
                      : ' this user'
                  }
                  ?
                </>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {!errorMessage && (
              <Button onClick={deleteUser} color="primary">
                Confirm
              </Button>
            )}
            <Button onClick={() => setUserForDeleting(null)} color="primary">
              {errorMessage ? 'Close' : 'Cancel'}
            </Button>
          </DialogActions>
        </Dialog>
        <UserEditForm
          isOpen={!!userToEdit}
          data={userToEdit ? { ...userToEdit } : {}}
          onCancel={closeEditProfileDialog}
          onSuccess={profileDataUpdated}
        />
        <RoleEditForm
          isOpen={!!roleToEdit}
          data={roleToEdit ? { ...roleToEdit } : {}}
          onCancel={closeEditRoleDialog}
          onSuccess={profileDataUpdated}
        />
        {(isLoading || isDeleteLoading) && <Loader />}
      </Container>
    </>
  );
};

export default Users;
