export const HOME_ROUTE = '/';

export const LOGIN_ROUTE = '/login';

export const ORDER_STATUSES = [
  { label: 'In transit', key: 'InTransit', value: 0 },
  { label: 'Loaded in the container', key: 'LoadedInTheContainer', value: 1 },
  { label: 'Unloaded', key: 'Unloaded', value: 2 },
  { label: 'Loaded on the truck', key: 'LoadedOnTheTruck', value: 3 },
  { label: 'Final destination', key: 'FinalDestination', value: 4 },
];

export const CONTAINER_STATUSES = [
  { label: 'Loaded', key: 'Loaded', value: 0 },
  { label: 'Delivered', key: 'Delivered', value: 1 },
];

export const TRUCK_STATUSES = [
  { label: 'Assigned', key: 'AssignedToTrailer', value: 0 },
  { label: 'Loaded', key: 'Loaded', value: 1 },
  { label: 'Delivered', key: 'Delivered', value: 2 },
];

export const ROLE_ADMIN = 'Admin';
export const ROLE_USER = 'User';
export const ROLE_WAREHOUSE = 'Warehouse';

export const ROLES_MAP = {
  ADMIN: ROLE_ADMIN,
  USER: ROLE_USER,
  WAREHOUSE: ROLE_WAREHOUSE,
};
