import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const RouterPrompt = ({
  when,
  onOK = () => true,
  onCancel = () => false,
  title = 'Leave this page?',
  message = 'There are unsaved changes. Are you sure you want to leave this page?',
  okText = 'Yes',
  cancelText = 'No'
}) => {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return 'true';
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());

      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
      <Dialog
        open={showPrompt}
        keepMounted
        onClose={handleCancel}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOK} color="primary">
            {okText}
          </Button>
          <Button onClick={handleCancel} color="primary">
            {cancelText}
          </Button>
        </DialogActions>
      </Dialog>
  ) : null;
};

export default RouterPrompt;
