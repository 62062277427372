import React from 'react';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CloudDownload } from '@material-ui/icons';

import { COLOR_BACKGROUND } from '../../../../assets/styles/variables';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: COLOR_BACKGROUND,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const DocumentsDetails = ({ billOfLading, billOfSales, vehicleTitle, cmr }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Documents</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              Bill of lading
            </StyledTableCell>
            <StyledTableCell>
              {billOfLading && Array.isArray(billOfLading) && billOfLading.length > 0 ?
                billOfLading.map((fileData, index) => (
                  <Button
                    key={`bill-of-lading-${index}`}
                    component="a"
                    href={fileData?.url || fileData}
                    rel="noopener noreferrer"
                    target="_blank"
                    color="primary"
                    variant="outlined"
                    size="small"
                    style={{ marginRight: '5px' }}
                  >
                    <CloudDownload />
                  </Button>
                )) : '-'}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              Bill of sales
            </StyledTableCell>
            <StyledTableCell>
              {billOfSales && Array.isArray(billOfSales) && billOfSales.length > 0 ?
                billOfSales.map((fileData, index) => (
                  <Button
                    key={`bill-of-sales-${index}`}
                    component="a"
                    href={fileData?.url || fileData}
                    rel="noopener noreferrer"
                    target="_blank"
                    color="primary"
                    variant="outlined"
                    size="small"
                    style={{ marginRight: '5px' }}
                  >
                    <CloudDownload />
                  </Button>
                )) : '-'}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              Vehicle title
            </StyledTableCell>
            <StyledTableCell>
              {vehicleTitle && Array.isArray(vehicleTitle) && vehicleTitle.length > 0 ?
                vehicleTitle.map((fileData, index) => (
                  <Button
                    key={`vehicle-title-${index}`}
                    component="a"
                    href={fileData?.url || fileData}
                    rel="noopener noreferrer"
                    target="_blank"
                    color="primary"
                    variant="outlined"
                    size="small"
                    style={{ marginRight: '5px' }}
                  >
                    <CloudDownload />
                  </Button>
                )) : '-'}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              CMR
            </StyledTableCell>
            <StyledTableCell>
              {cmr && Array.isArray(cmr) && cmr.length > 0 ?
                cmr.map((fileData, index) => (
                  <Button
                    key={`cmr-${index}`}
                    component="a"
                    href={fileData?.url || fileData}
                    rel="noopener noreferrer"
                    target="_blank"
                    color="primary"
                    variant="outlined"
                    size="small"
                    style={{ marginRight: '5px' }}
                  >
                    <CloudDownload />
                  </Button>
                )) : '-'}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DocumentsDetails;
