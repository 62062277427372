import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  Grid, Link,
  TextField,
  Typography
} from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';

import Loader from '../../../components/Loader';
import { post } from '../../../shared/api';
import { HOME_ROUTE } from '../../../shared/contants';
import { getErrorMessage } from '../../../shared/helpers';
import { JWT_TOKEN_COOKIE_NAME, setCookie } from '../../../shared/web-storage';
import { setUserFromTokenAction } from '../../../store/actions/auth.actions';

import styles from './LoginForm.module.scss'

const LoginForm = () => {
  const { control, handleSubmit } = useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = async (data) => {
    setIsLoading(true);
    setErrorMessage('');

    await post('/identity/login', data)
      .then(({ token }) => {
        setCookie(JWT_TOKEN_COOKIE_NAME, token);
        dispatch(setUserFromTokenAction(token));
        history.push(HOME_ROUTE);
      })
      .catch((error) => {
        setErrorMessage(getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container component="main" maxWidth="sm" className={styles.authCard}>
      <CssBaseline />
      <div className={styles.wrapper}>
        <div className={styles.accentImage} />
        <div className={styles.headerWrapper}>
          <div className={styles.avatarWrapper}>
            <Avatar className={styles.avatar}>
              <LockOutlined />
            </Avatar>
          </div>
          <Typography className={styles.headerText} component="h1" variant="h5">
            Sign in
          </Typography>
        </div>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    variant="outlined"
                    required
                    fullWidth
                    autoComplete="email"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Password"
                    variant="outlined"
                    type="password"
                    required
                    fullWidth
                    autoComplete="current-password"
                  />
                )}
              />
            </Grid>
            {errorMessage && (
              <Grid item xs={12}>
                <Typography color="error" variant="body2">
                  {errorMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={styles.submitButton}
          >
            Sign In
          </Button>
          <br />
          <Grid container className={styles.additionalActions}>
            <Grid item xs={12} sm={6}>
              <Link
                component={RouterLink}
                to="/forgotten"
                style={{ textDecoration: 'none' }}
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} className={styles.registerAction}>
              Don't have an account?
              &nbsp;
              <Link
                component={RouterLink}
                to="/register"
                style={{ textDecoration: 'none' }}
              >
                Sign up
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      {isLoading && <Loader />}
    </Container>
  );
};

export default LoginForm;
