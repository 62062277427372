import React from 'react';
import { Button, Typography, Grid } from '@material-ui/core';
import ImageGallery from 'react-image-gallery';

const VehicleGallery = ({ title, images, showGallery, toggleGallery, downloadImages }) => {
 
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {title} Gallery
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {images.length ? (
            <div>
              {showGallery && (
                <ImageGallery
                  autoPlay={false}
                  showPlayButton={false}
                  items={images.map(image => ({
                    original: image?.url || image,
                    thumbnail: image?.url || image,
                  }))}
                />
              )}
              <div>
                <Button
                  size="small"
                  onClick={toggleGallery}
                  variant="contained"
                  color="primary"
                >
                  {showGallery ? 'Close' : 'Open'} {title} gallery
                </Button> &nbsp;
                <Button
                  size="small"
                  onClick={downloadImages}
                  variant="contained"
                  color="primary"
                >
                  Download all {title} images
                </Button>
              </div>
            </div>
          ) : (
            <Typography variant="body2" gutterBottom>
              No images
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VehicleGallery;
