import React, { useState } from 'react';
import { Button, Container } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
// import { Facebook, Instagram, MapRounded, DirectionsBoat } from '@material-ui/icons';

import VimModal from '../../components/VimModal';

import styles from './Home.module.scss';

const Home = () => {
  const [isVimModalOpen, setIsVimModalOpen] = useState(false);

  return (
    <div>
      <Container className={styles.Container} maxWidth="lg">
        <div className={styles.homeIntro} />
        <div className={styles.action}>
          <div>
            <Button
              size="small"
              className={styles.VimButton}
              onClick={() => setIsVimModalOpen(true)}
            >
              <MyLocationIcon />&nbsp;Track Vehicle
            </Button>
          </div>
          {/*<div className={styles.social}>*/}
          {/*  <IconButton*/}
          {/*    aria-label="google-maps"*/}
          {/*    type="a"*/}
          {/*    size="medium"*/}
          {/*    style={{ color: '#fcdc51' }}*/}
          {/*    href="https://goo.gl/maps/tx8tzbxXjeHVXbGc6"*/}
          {/*    target="_blank"*/}
          {/*    rel="noopener noreferrer"*/}
          {/*  >*/}
          {/*    <MapRounded />*/}
          {/*  </IconButton>*/}
          {/*  <IconButton*/}
          {/*    aria-label="google-maps"*/}
          {/*    type="a"*/}
          {/*    size="medium"*/}
          {/*    style={{ color: '#fcdc51' }}*/}
          {/*    href="https://www.facebook.com/MOTUS.Shipping.GmbH"*/}
          {/*    target="_blank"*/}
          {/*    rel="noopener noreferrer"*/}
          {/*  >*/}
          {/*    <Facebook />*/}
          {/*  </IconButton>*/}
          {/*  <IconButton*/}
          {/*    aria-label="google-maps"*/}
          {/*    type="a"*/}
          {/*    size="medium"*/}
          {/*    style={{ color: '#fcdc51' }}*/}
          {/*    href="https://www.instagram.com/motus_shipping/?hl=de"*/}
          {/*    target="_blank"*/}
          {/*    rel="noopener noreferrer"*/}
          {/*  >*/}
          {/*    <Instagram />*/}
          {/*  </IconButton>*/}
          {/*</div>*/}
        </div>
      </Container>
      <VimModal open={isVimModalOpen} handleClose={() => setIsVimModalOpen(false)} />
    </div>
  );
};

export default Home;
