import React, { useEffect, useState } from 'react';
import { Link as RouterLink, Redirect, Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router';
import { AppBar, Container, Paper, Tab, Tabs } from '@material-ui/core';

import AuctionLocations from './components/AuctionLocations';
import AuctionNames from './components/AuctionNames';
import ClearanceCompanies from './components/ClearanceCompanies';
import EmailContacts from './components/EmailContacts';
import Terminals from './components/Terminals';

import styles from './Configuration.module.scss';

const CONFIGURATION_TABS_ROUTES = [
  {
    label: 'Auction names',
    href: 'auction-names',
  },
  {
    label: 'Auction locations',
    href: 'auction-locations',
  },
  {
    label: 'Clearance companies',
    href: 'clearance-companies',
  },
  {
    label: 'Terminals',
    href: 'terminals',
  },
  {
    label: 'Email contacts',
    href: 'contacts',
  },
];

const Configuration = () => {
  const [value, setValue] = useState(0);
  const { pathname } = useLocation();

  useEffect(() => {
    const currentPath = pathname.split('/')?.pop();

    if (currentPath) {
      const currentTabIndex = CONFIGURATION_TABS_ROUTES.findIndex(route => route.href === currentPath);

      if (currentTabIndex >= 0) {
        setValue(currentTabIndex);
      }
    }
  }, [pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <br />
      <Container>
        <Paper square>
          <AppBar position="static">
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="nav tabs example"
            >
              {CONFIGURATION_TABS_ROUTES.map(({ label, href }, index) => (
                <Tab
                  id={`nav-tab-${index}`}
                  key={`nav-tab-${index}`}
                  component={RouterLink}
                  label={label}
                  to={`/configuration/${href}`}
                  aria-controls={`nav-tabpanel-${index}`}
                  className={styles.tab}
                />
              ))}
            </Tabs>
          </AppBar>
          <Switch>
            <Route path="/configuration/auction-names" exact component={AuctionNames} />
            <Route path="/configuration/auction-locations" exact component={AuctionLocations} />
            <Route path="/configuration/clearance-companies" exact component={ClearanceCompanies} />
            <Route path="/configuration/terminals" exact component={Terminals} />
            <Route path="/configuration/contacts" exact component={EmailContacts} />
            <Redirect from="*" to="/configuration/auction-names" />
          </Switch>
        </Paper>
        <br />
      </Container>
    </>
  );
};

export default Configuration;
