import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText, TextField
} from '@material-ui/core';

import { intersection, notIn, union } from '../../shared/helpers';

import styles from './TransferList.module.scss';

const TransferList = ({
  initialLeftItems = [],
  initialRightItems = [],
  handleChanged,
  leftLabel = 'Items',
  rightLabel = 'Selected',
}) => {
  const [checked, setChecked] = React.useState([]);
  const [leftItems, setLeftItems] = React.useState([]);
  const [leftFilterTerm, setLeftFilterTerm] = React.useState('');
  const [rightItems, setRightItems] = React.useState([]);
  const [rightFilterTerm, setRightFilterTerm] = React.useState('');

  useEffect(() => {
    setLeftItems([...initialLeftItems]);
    setRightItems([...initialRightItems]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const leftItemsChecked = intersection(checked, leftItems);
  const rightItemsChecked = intersection(checked, rightItems);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(notIn(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const newSelection = rightItems.concat(leftItemsChecked);

    setRightItems(newSelection);
    setLeftItems(notIn(leftItems, leftItemsChecked));
    setChecked(notIn(checked, leftItemsChecked));
    setLeftFilterTerm('');
    setRightFilterTerm('');

    handleChanged && handleChanged(newSelection);
  };

  const handleCheckedLeft = () => {
    const newSelection = notIn(rightItems, rightItemsChecked);
    setLeftItems(leftItems.concat(rightItemsChecked));
    setRightItems(newSelection);
    setChecked(notIn(checked, rightItemsChecked));
    setRightFilterTerm('');
    setLeftFilterTerm('');

    handleChanged && handleChanged(newSelection);
  };

  const changeFilterTerm = (event, setNewTerm) => {
    event.preventDefault();
    setNewTerm(event.target.value);
  };

  const customList = (title, items, filterTerm = '', filterChangedHandler) => (
    <Card className={styles.selectionCard} elevation={0}>
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0 || filterTerm.length > 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <TextField
        fullWidth
        className={styles.filterInput}
        placeholder="Filter"
        value={filterTerm}
        onInput={(event) => changeFilterTerm(event, filterChangedHandler)}
      />
      <Divider />
      <List
        dense
        component="div"
        role="list"
        className={styles.list}
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          const showOption = !filterTerm || value.toLowerCase().includes(filterTerm.toLowerCase());

          return showOption && (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={5}>{customList(leftLabel, leftItems, leftFilterTerm, setLeftFilterTerm)}</Grid>
      <Grid item xs={12} sm={2}>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftItemsChecked.length === 0}
            aria-label="move selected rightItems"
          >
            &gt;
          </Button>
          <br />
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightItemsChecked.length === 0}
            aria-label="move selected leftItems"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5}>{customList(rightLabel, rightItems, rightFilterTerm, setRightFilterTerm)}</Grid>
    </Grid>
  );
};

export default TransferList;
