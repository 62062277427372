import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { withStyles } from '@material-ui/core/styles';

import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';

import './index.scss';

const styles = theme => ({
  '@global': {
    html: {
      fontSize: 12,
      [theme.breakpoints.up('sm')]: {
        fontSize: 14
      },
    }
  }
});

const ReactApp = withStyles(styles)(App);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      <ToastProvider autoDismiss placement="top-center">
        <HelmetProvider>
          <ReactApp />
        </HelmetProvider>
      </ToastProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
