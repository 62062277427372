import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Divider,
  FormControl,
  Grid, IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField, Typography
} from '@material-ui/core';
import { AttachFile, Delete } from '@material-ui/icons';

import { MAIN_TITLE } from '../../assets/styles/variables';
import Loader from '../../components/Loader';
import TransferList from '../../components/TransferList';
import { del, get, post, put } from '../../shared/api';
import { ROLE_ADMIN, ROLE_WAREHOUSE, TRUCK_STATUSES } from '../../shared/contants';
import { getDateFormValue, getErrorMessage } from '../../shared/helpers';
import { selectUser } from '../../store/selectors/auth.selectors';

import styles from '../Login/components/LoginForm.module.scss';
import galleryStyles from '../OrderForm/OrderForm.module.scss';

const TruckForm = () => {
  const { addToast } = useToasts();
  const { control, handleSubmit, reset } = useForm();
  const [truckToEdit, setTruckToEdit] = useState(null);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [currentTruckLoadedVehicles, setCurrentTruckLoadedVehicles] = useState(null);
  const [areVehiclesLoading, setAreVehiclesLoading] = useState(false);
  const [truckCompanies, setTruckCompanies] = useState([]);
  const currentUser = useSelector(selectUser);
  const [errorMessage, setErrorMessage] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const history = useHistory();
  const [imageToDelete, setImageToDelete] = useState('');
  const [isAddImageDialogOpen, setIsAddImagesDialogOpen] = useState(false);
  const [currentImages, setCurrentImages] = useState(null);
  const [errorMessageUpload, setErrorMessageUpload] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isUnloaded, setIsUnloaded] = useState(false); 
  const [loadedImages, setLoadedImages] = useState([]);
  const [unloadedImages, setUnloadedImages] = useState([]);
  const { id } = useParams();

  const loadVehicles = () => {
    setAreVehiclesLoading(true);

    let vehiclesUrl = '/vehicles/my';
    if (currentUser.role === ROLE_ADMIN) {
      vehiclesUrl = '/vehicles/container';
    } else if (currentUser.role === ROLE_WAREHOUSE) {
      vehiclesUrl = `/vehicles/warehouse/${currentUser.email}`;
    }

    get(vehiclesUrl)
      .then((vehiclesData) => {
        if (Array.isArray(vehiclesData)) {
          setVehicles(vehiclesData);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setAreVehiclesLoading(false);
      });
  }

  const loadTruckCompanies = async () => {
    await get('/truck-companies')
      .then((data) => {
        if (Array.isArray(data)) {
          data.map(truckData => ({
            ...truckData,
            expectedDate: getDateFormValue(data.expectedDate),
          }));

          setTruckCompanies(data);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });
  }

  useEffect(() => {
    loadTruckCompanies();
    loadVehicles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCurrentVehicle = async () => {
    if (id) {
      get(`/trucks/id/${id}`)
        .then((trucksData) => {
          if (trucksData && trucksData.id) {
            setCurrentTruckLoadedVehicles(trucksData.vehicleIds);
            setTruckToEdit(trucksData);
            reset({
              ...trucksData,
              truckCompany: trucksData?.truckCompany?.id || '',
              status: TRUCK_STATUSES.find(status => status.value === trucksData?.status)?.value,
            },
            );
            loadImages();
          }
        })
        .catch((error) => {
          addToast(getErrorMessage(error), { appearance: 'error' });
        })
        .finally(() => {
          setIsEditLoading(false);
        });
    } else {
      reset({
        plateNumber: '',
        expectedDate: '',
        truckCompany: '',
        status: '',
        notes: '',
      });
    }
  };

  useEffect(() => {
    loadCurrentVehicle();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const selectFile = (event) => {
    setCurrentFile(event.target.files[0]);
  };

  const setLoadedVehicles = (vehicleVins) => {
    setCurrentTruckLoadedVehicles(
      vehicles
        .filter(vehicle => vehicleVins.includes(vehicle.vin))
        .map(vehicle => vehicle.vehicleId)
    );
  };

  const goBack = (event) => {
    event.preventDefault();

    history.goBack();
  };

  const closeImageUploadDialog = () => {
    setIsAddImagesDialogOpen(false);
    setCurrentImages(null);
    setErrorMessageUpload('');
  };

  const editTruck = async (data) => {
    setIsEditLoading(true);

    const {
      expectedDate,
      plateNumber,
      truckCompany,
      status,
      notes,
    } = data;

    let formData = {
      plateNumber,
      status,
      vehicleIds: currentTruckLoadedVehicles,
      username: currentUser.email,
      notes,
    };

    if (truckCompany) {
      formData = {
        ...formData,
        truckCompanyId: truckCompany,
      };
    }

    if (expectedDate) {
      formData = {
        ...formData,
        expectedDate,
      };
    }

    if (id) {
      await put(`/trucks/${id}`, formData)
        .then((response) => {
          if (response) {
            setErrorMessage('');
            addToast(
              'Truck updated successfully!',
              { appearance: 'success' }
            );

            if (currentFile) {
              let fileUploadFormData = new FormData();
              fileUploadFormData.append('files', currentFile);
              fileUploadFormData.append('typeOfFile', 'CMR');

              for (let index = 0; index < formData.vehicleIds?.length; index++) {
                fileUploadFormData.append('vehicleIds[]', formData.vehicleIds[index]);
              }

              post(
                `/pdf/upload/multiple`,
                fileUploadFormData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
              )
                .then((response) => {
                  if (response) {
                    setCurrentFile(null);
                    addToast('CMR successfully uploaded!', { appearance: 'success' });
                    loadCurrentVehicle();
                    loadVehicles();
                  }
                })
                .catch((error) => {
                  console.log(error);
                  addToast(getErrorMessage(error), { appearance: 'error' });
                })
                .finally(() => {
                  setIsEditLoading(false);
                });
            } else {
              loadCurrentVehicle();
              loadVehicles();
            }
          }
        })
        .catch((error) => {
          setErrorMessage(getErrorMessage(error));
        })
        .finally(() => {
          setIsEditLoading(false);
        });
    } else {
      await post(`/trucks`, formData)
        .then((response) => {
          if (response) {
            setErrorMessage('');
            addToast(
              'Truck created successfully!',
              { appearance: 'success' }
            );

            if (currentFile) {
              let fileUploadFormData = new FormData();
              fileUploadFormData.append('files', currentFile);
              fileUploadFormData.append('typeOfFile', 'CMR');

              for (let index = 0; index < formData.vehicleIds?.length; index++) {
                fileUploadFormData.append('vehicleIds[]', formData.vehicleIds[index]);
              }

              post(
                `/pdf/upload/multiple`,
                fileUploadFormData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
              )
                .then((response) => {
                  if (response) {
                    setCurrentFile(null);
                    addToast('CMR successfully uploaded!', { appearance: 'success' });
                    loadVehicles();
                  }
                })
                .catch((error) => {
                  console.log(error);
                  addToast(getErrorMessage(error), { appearance: 'error' });
                })
                .finally(() => {
                  setIsEditLoading(false);
                });
            } else {
              loadVehicles();
            }
          }
        })
        .catch((error) => {
          setErrorMessage(getErrorMessage(error));
        })
        .finally(() => {
          setIsEditLoading(false);
        });
    }
  };

  const uploadImage = (isUnloaded) => {
    setIsLoading(true);
    setErrorMessageUpload('');

    if (currentImages && currentImages.length) {
      let formData = new FormData();
      currentImages.forEach(image => {
        formData.append('files', image);
      });

      let url = isUnloaded
        ? `/images/upload/${id}?isUnloaded=true`
        : `/images/upload/${id}`;

      post(url, formData)
        .then((response) => {
          if (response) {
            setIsAddImagesDialogOpen(false);
            setCurrentImages(null);
            addToast('Images uploaded successfully!', { appearance: 'success' });
            loadImages();
          }
        })
        .catch((error) => {
          setErrorMessageUpload(getErrorMessage(error));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setErrorMessageUpload('You must select an image!');
    }
  };

  const loadImages = () => {
    get(`/images/get/${id}`)
      .then((images) => {
        if (images && Array.isArray(images)) {
          setLoadedImages(images.filter((image) => !!image));
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });

    get(`/images/get/${id}?isUnloaded=true`)
      .then((images) => {
        if (images && Array.isArray(images)) {
          setUnloadedImages(images.filter((image) => !!image));
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });
  };


  const selectImages = (event) => {
    const numberOfSelectedImages = event.target.files?.length || 0;
    const imagesToUpload = [];

    if (numberOfSelectedImages) {
      for (let index = 0; index < numberOfSelectedImages; index++) {
        imagesToUpload.push(event.target.files[index]);
      }
    }

    if (imagesToUpload.length) {
      setCurrentImages(imagesToUpload);
    }
  };

  const handleUploadClick = (isUnloaded) => {
    uploadImage(isUnloaded);
  }

  const deleteImage = () => {
    del(`/images/${id}/${imageToDelete}`)
      .then((response) => {
        if (response) {
          addToast(response, { appearance: 'success' });
          setImageToDelete('');
          loadImages();
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });
  };

  const deleteAllImages = () => {
    del(`/images/deleteAll/${id}`)
      .then((response) => {
        if (response) {
          addToast(response, { appearance: 'success' });
          loadImages();
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });
  };

  const deleteFile = () => {
    if (!fileToDelete) {
      return;
    }

    const {
      cmr = {},
      vehicleIds = [],
    } = fileToDelete;

    const { title } = cmr;

    if (!title) {
      return;
    }

    setIsDeleteLoading(true);

    put(`/pdf/delete/multiple`, { title, vehicleIds })
      .then((response) => {
        if (response) {
          addToast('File removed successfully!', { appearance: 'success' });
          setCurrentFile(null);

          closeDeleteFileDialog();
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsDeleteLoading(false);
      });
  };

  const closeDeleteFileDialog = () => {
    setFileToDelete(null);
  };

  return (
    <>
      <Helmet>
        <title>Truck {id ? 'edit' : 'create'} | {MAIN_TITLE}</title>
      </Helmet>
      <Container component="main">
        <Card>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              {id ? 'Edit' : 'Create new'} truck
            </Typography>
            <Divider />
            <br />
            <form onSubmit={handleSubmit(editTruck)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <Controller
                    name="plateNumber"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Plate number"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="status" variant="outlined" required>Status</InputLabel>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="status"
                          label="Status"
                          variant="outlined"
                          required
                          fullWidth
                        >
                          {TRUCK_STATUSES.map(({ key, label, value }) => (
                            <MenuItem key={`status-${key}`} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={truckToEdit && !truckToEdit.id ? 6 : 9}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="truckCompany" variant="outlined">Truck company</InputLabel>
                    <Controller
                      name="truckCompany"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="truckCompany"
                          label="Truck company"
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem key="truck-company-none" value="">-- Clear --</MenuItem>
                          {truckCompanies.map(({ id, name, trackUrl }) => (
                            <MenuItem key={`truck-company-${id}`} value={id}>
                              {name} {trackUrl && `(${trackUrl})`}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="expectedDate"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label="Expected date"
                        type="date"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                {truckToEdit && (
                  <Grid item xs={12} md={6}>
                    {truckToEdit.id ?
                      truckToEdit.cmr ? (
                        <div style={{ padding: '6px 0 0 4px' }}>
                          <span>CMR:&nbsp;</span>
                          <IconButton
                            component="a"
                            href={truckToEdit.cmr?.url || truckToEdit.cmr}
                            rel="noopener noreferrer"
                            target="_blank"
                            color="primary"
                            area-label="download"
                            size="small"
                            title={truckToEdit.cmr?.title || truckToEdit.cmr}
                          >
                            <AttachFile fontSize="small" />
                          </IconButton>
                          &nbsp;
                          <IconButton
                            aria-label="delete"
                            type="button"
                            size="small"
                            onClick={() => {
                              setFileToDelete(truckToEdit);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      ) : (
                        <label htmlFor="btn-upload-cmr">
                          <input
                            id="btn-upload-cmr"
                            name="btn-upload-cmr"
                            style={{ display: 'none' }}
                            type="file"
                            onChange={selectFile}
                          />
                          <Button
                            className="btn-choose"
                            variant="outlined"
                            component="span"
                            fullWidth
                            style={{ minHeight: '37px' }}
                          >
                            {currentFile
                              ? (
                                <span style={{
                                  width: '100%',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}>
                                  CMR: {currentFile.name}
                                </span>
                              )
                              : 'Upload "CMR"'}
                          </Button>
                        </label>
                      )
                      : (
                        <label htmlFor="btn-upload-cmr">
                          <input
                            id="btn-upload-cmr"
                            name="btn-upload-cmr"
                            style={{ display: 'none' }}
                            type="file"
                            onChange={selectFile}
                          />
                          <Button
                            className="btn-choose"
                            variant="outlined"
                            component="span"
                            fullWidth
                            style={{ minHeight: '37px' }}
                          >
                            {currentFile
                              ? (
                                <span style={{
                                  width: '100%',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}>
                                  CMR: {currentFile.name}
                                </span>
                              )
                              : 'Upload "CMR"'}
                          </Button>
                        </label>
                      )}
                  </Grid>
                )}
                {(truckToEdit && truckToEdit.vehicleIds && vehicles.length) ? (
                  <Grid item xs={12}>
                    <TransferList
                      leftLabel="Vehicles"
                      rightLabel="Loaded vehicles"
                      initialLeftItems={vehicles.filter(v => !(v.truck && v.truck.plateNumber)).map(v => v.vin)}
                      initialRightItems={vehicles.filter(v => truckToEdit.vehicleIds.includes(v.vehicleId)).map(v => v.vin)}
                      handleChanged={setLoadedVehicles}
                    />
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <Controller
                    name="notes"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Notes"
                        variant="outlined"
                        multiline
                        rows={2}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                {errorMessage && (
                  <Grid item xs={12}>
                    <Typography color="error" variant="body2">
                      {errorMessage}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {id && (
                <>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} className="gallery">
                        <Grid container>
                          <Grid item xs={8}>
                            <Typography variant="h6" gutterBottom>
                              Gallery
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Box textAlign="right">
                              <Button
                                onClick={() => setIsAddImagesDialogOpen(true)}
                                variant="contained"
                                color="primary"
                              >
                                Add images
                              </Button>
                              <Button
                                onClick={deleteAllImages}
                                variant="contained"
                                color="secondary"
                              >
                                Delete all images
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      {loadedImages.length ? (
                        loadedImages.map((imageData, index) => (
                          <Grid item xs={12} sm={6} md={3} key={`image-${index}`}>
                            <div className={galleryStyles.imageWrapper}>
                              <div className={galleryStyles.removeImageButton}>
                                <IconButton
                                  aria-label="delete"
                                  type="button"
                                  className={galleryStyles.remove}
                                  onClick={() => setImageToDelete(imageData?.title || imageData)}
                                >
                                  <Delete />
                                </IconButton>
                              </div>
                              <img
                                style={{ width: '100%' }}
                                src={imageData?.url || imageData}
                                alt={`preview-${index}`}
                              />
                            </div>
                          </Grid>
                        ))
                      ) : (
                        <Grid item xs={12}>
                          <Typography variant="body2" gutterBottom>
                            No images
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} className="gallery">
                        <Grid container>
                          <Grid item xs={8}>
                            <Typography variant="h6" gutterBottom>
                              Unloaded
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {unloadedImages.length ? (
                        unloadedImages.map((imageData, index) => (
                          <Grid item xs={12} sm={6} md={3} key={`image-${index}`}>
                            <div className={galleryStyles.imageWrapper}>
                              <div className={galleryStyles.removeImageButton}>
                                <IconButton
                                  aria-label="delete"
                                  type="button"
                                  className={galleryStyles.remove}
                                  onClick={() => setImageToDelete(imageData?.title || imageData)}
                                >
                                  <Delete />
                                </IconButton>
                              </div>
                              <img
                                style={{ width: '100%' }}
                                src={imageData?.url || imageData}
                                alt={`preview-${index}`}
                              />
                            </div>
                          </Grid>
                        ))
                      ) : (
                        <Grid item xs={12}>
                          <Typography variant="body2" gutterBottom>
                            No images
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Grid
                  container
                  justify={history.length > 1 ? 'space-between' : 'flex-end'}
                  style={{ paddingTop: '10px' }}
                >
                  {history.length > 1 && (
                    <Button
                      className={styles.backButton}
                      variant="outlined"
                      onClick={goBack}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    className={styles.submitButton}
                  >
                    {id ? 'Save' : 'Create truck'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
        <Dialog open={isAddImageDialogOpen} onClose={closeImageUploadDialog} fullWidth maxWidth="xs">
          <DialogTitle>Add images</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <label htmlFor="contained-button-file">
                <label htmlFor="btn-upload">
                  <input
                    id="btn-upload"
                    name="btn-upload"
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={selectImages}
                  />
                  <Button className="btn-choose" variant="outlined" component="span">
                    Choose Image
                  </Button>
                </label>
                <br />
                <br />
                <Typography variant="body2" gutterBottom>
                  {currentImages && currentImages.length ? (
                    currentImages.map((image) => <div key={`image-name-${image.name}`}>{image.name}</div>)
                  ) : (
                    'No images selected'
                  )}
                </Typography>
                {errorMessageUpload && (
                  <Typography color="error" variant="body2">
                    {errorMessageUpload}
                  </Typography>
                )}
              </label>
            </DialogContentText>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="image-type">Image Option</InputLabel>
              <Controller
                name="isUnloaded"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="image-type"
                    id="image-type-select"
                    label="Image Option"
                    value={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      setIsUnloaded(event.target.value);
                    }}
                  >
                    <MenuItem value={false}>Loaded</MenuItem>
                    <MenuItem value={true}>Unloaded</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleUploadClick(isUnloaded)} color="primary">
              Save
            </Button>
            <Button onClick={closeImageUploadDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!!imageToDelete}
          onClose={() => setImageToDelete('')}
        >
          <DialogTitle>Delete image</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this image?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteImage} color="primary">
              Confirm
            </Button>
            <Button onClick={() => setImageToDelete('')} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!!fileToDelete}
          onClose={closeDeleteFileDialog}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle>Delete file from truck</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to remove the attached file from the truck?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteFile} color="primary">
              Confirm
            </Button>
            <Button onClick={closeDeleteFileDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      {(isLoading || isEditLoading || areVehiclesLoading || isDeleteLoading) && <Loader />}
    </>
  );
};

export default TruckForm;
