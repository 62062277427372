import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import Loader from '../../../components/Loader';
import { put } from '../../../shared/api';
import { getErrorMessage } from '../../../shared/helpers';

const ModifyNotificationsDialog = ({ isOpen, onSuccess, onCancel, userId, areNotificationsEnabled }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const modifyNotifications = async () => {
    setIsLoading(true);

    await put(`/identity/modifyNotifications/${userId}?isEnabled=${!areNotificationsEnabled}`)
      .then((response) => {
        if (response) {
          addToast(
            `Notifications ${areNotificationsEnabled ? 'disabled' : 'enabled'} successfully!`,
            { appearance: 'success' }
          );

          onSuccess && onSuccess();
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleCancel}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          {areNotificationsEnabled ? 'Disable' : 'Enable'} notifications confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {areNotificationsEnabled ? 'disable' : 'enable'} notifications?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={modifyNotifications} color="primary">
            Confirm
          </Button>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading && <Loader />}
    </>
  );
};

export default ModifyNotificationsDialog;
