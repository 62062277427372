import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { CheckBox, CheckBoxOutlineBlank, Delete, Edit } from '@material-ui/icons';
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { MAIN_TITLE } from '../../../../assets/styles/variables';
import { del, get, post, put } from '../../../../shared/api';
import { getErrorMessage } from '../../../../shared/helpers';
import Loader from '../../../../components/Loader';
import Table from '../../../../components/Table';

const Locations = () => {
  const [items, setItems] = useState([]);
  const [auctions, setAuctions] = useState([]);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { addToast } = useToasts();
  const { control, handleSubmit, reset } = useForm();

  const loadItems = async () => {
    setIsLoading(true);

    await get('/auctions')
      .then((data) => {
        if (Array.isArray(data)) {
          setAuctions(data);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });

    await get('/locations')
      .then((data) => {
        if (Array.isArray(data)) {
          setItems(data.map(formData => ({
            ...formData,
              auctionNames: formData.auctions && formData.auctions.length
                ? formData.auctions.map(({ name }) => name).filter(auction => !!auction).join(', ')
                : '',
          })));
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editItem = async (data) => {
    setIsActionLoading(true);
    const formData = { ...data, auctions: data.auctions.map(auction => auction.id) };

    if (formData.id) {
      await put('/locations', {
        ...formData,
      })
        .then((response) => {
          if (response) {
            setErrorMessage('');
            addToast(
              'Location updated successfully!',
              { appearance: 'success' }
            );

            closeDialog();
            loadItems();
          }
        })
        .catch((error) => {
          setErrorMessage(getErrorMessage(error));
        })
        .finally(() => {
          setIsActionLoading(false);
        });
    } else {
      await post('/locations', {
        ...formData,
      })
        .then((response) => {
          if (response) {
            setErrorMessage('');
            addToast('Location created!', { appearance: 'success' });

            closeDialog();
            loadItems();
          }
        })
        .catch((error) => {
          setErrorMessage(getErrorMessage(error));
        })
        .finally(() => {
          setIsActionLoading(false);
        });
    }
  };

  const openEditItemDialog = (item) => {
    const currentAuctions = auctions.filter(({ id }) => item.auctions?.includes(id));
    setItemToEdit(item);
    reset({ ...item, auctions: currentAuctions || [] });
  };

  const closeDialog = () => {
    setItemToEdit(null);
    setItemToDelete(null);
    reset({ name: '', auctions: [] });
  };

  const deleteItem = () => {
    if (!itemToDelete) {
      return;
    }

    setIsActionLoading(true);

    del(`/locations/${itemToDelete.id}`)
      .then((response) => {
        if (response) {
          addToast('Location deleted successfully!', { appearance: 'success' });

          closeDialog();
          loadItems();
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsActionLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Locations | {MAIN_TITLE}</title>
      </Helmet>
      <Grid container>
        <Grid item xs={12}>
          <Table
            title="Locations"
            columns={[
              { title: 'Name', field: 'name', render: location => location.name || '-' },
              {
                title: 'Auctions', field: 'auctions', sorting: false, render: location => {
                  const auctionsNames = [];

                  if (location.auctions?.length) {
                    location.auctions.forEach(({ id: auctionId }) => {
                      const auctionName = auctions.find(auction => auction.id === auctionId);
                      if (auctionName) {
                        auctionsNames.push(auctionName);
                      }
                    });
                  }

                  return auctionsNames.length ? auctionsNames.map(auction => (
                    <React.Fragment key={auction.id}>
                      <Chip label={auction.name} style={{ marginBottom: '4px' }} />&nbsp;
                    </React.Fragment>
                  )) : '-';
                },
              },
              {
                align: 'right',
                cellStyle: {
                  width: 20,
                  maxWidth: 20
                },
                headerStyle: {
                  width: 20,
                  maxWidth: 20
                },
                searchable: false,
                sorting: false,
                render: item => (
                  <>
                    <IconButton
                      aria-label="edit"
                      type="button"
                      onClick={() => openEditItemDialog({
                        ...item,
                        auctions: item.auctions.map(auction => auction.id)
                      })}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      type="button"
                      onClick={() => setItemToDelete(item)}
                    >
                      <Delete />
                    </IconButton>
                  </>
                ),
              },
              {
                title: '',
                hidden: true,
                searchable: true,
                field: 'auctionNames',
                render: location => location.auctionNames || '-',
              },
            ]}
            data={items}
            options={{
              paging: false,
              maxBodyHeight: 'calc(100vh - 250px)',
            }}
            actions={[
              {
                icon: '+',
                tooltip: 'Add new',
                isFreeAction: true,
                onClick: () => openEditItemDialog({ name: '', auctions: [] }),
              }
            ]}
          />
        </Grid>
      </Grid>
      <Dialog
        open={!!itemToEdit}
        onClose={closeDialog}
        fullWidth
        maxWidth="xs"
      >
        <form onSubmit={handleSubmit(editItem)}>
          <DialogTitle>{itemToEdit?.id ? `Edit "${itemToEdit?.name}"` : 'Create new'}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Location"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      id="auctions"
                      multiple
                      aria-required="true"
                      options={auctions}
                      autoHighlight
                      getOptionLabel={option => option.name}
                      renderOption={(option, { selected }) => (
                        <>
                          <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBox color="primary" fontSize="small" />}
                            checked={selected}
                          />
                          {option.name}
                        </>
                      )}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Auctions"
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'disabled',
                          }}
                        />
                      )}
                      onChange={(_, data) => field.onChange(data)}
                    />
                  )}
                  name="auctions"
                  control={control}
                  defaultValue={[]}
                />
              </Grid>
              {errorMessage && (
                <Grid item xs={12}>
                  <Typography color="error" variant="body2">
                    {errorMessage}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={!!itemToDelete}
        onClose={closeDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Delete confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {itemToDelete?.name ? itemToDelete?.name : 'this item'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteItem} color="primary">
            Confirm
          </Button>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {(isLoading || isActionLoading) && <Loader />}
    </>
  );
};

export default Locations;
