import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';

import Loader from '../../../components/Loader';
import { post } from '../../../shared/api';
import { getErrorMessage } from '../../../shared/helpers';

import styles from './RegisterForm.module.scss';

const RegisterForm = () => {
  const { control, handleSubmit } = useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [isMailSent, setIsMailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    setErrorMessage('');

    const { phoneNumber, phonePrefix, ...formData } = data;

    await post('/identity/register', {
      ...formData,
      phoneNumber: `${phonePrefix}${phoneNumber}`
    })
      .then((response) => {
        if (response) {
          setIsMailSent(true);
        }
      })
      .catch((error) => {
        setErrorMessage(getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container component="main" maxWidth="sm" className={styles.authCard}>
      <div className={styles.wrapper}>
        <div className={styles.accentImage} />
        <div className={styles.headerWrapper}>
          <div className={styles.avatarWrapper}>
            <Avatar className={styles.avatar}>
              <LockOutlined />
            </Avatar>
          </div>
          <Typography className={styles.headerText} component="h1" variant="h5">
            Register
          </Typography>
        </div>
        {
          !isMailSent ? (
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="firstName"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="First name"
                        variant="outlined"
                        required
                        autoComplete="first-name"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="lastName"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Last name"
                        variant="outlined"
                        required
                        autoComplete="last-name"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="phone-prefix" variant="outlined" required>Prefix</InputLabel>
                    <Controller
                      name="phonePrefix"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          classes={{
                           selectMenu: styles.phonePrefix,
                          }}
                          labelId="phone-prefix"
                          label="Prefix"
                          variant="outlined"
                          fullWidth
                          required
                        >
                          <MenuItem value="+370">
                            <span className={styles.hiddenSelect}>(</span>
                            +370
                            <span className={styles.hiddenSelect}>) Lithuania</span>
                          </MenuItem>
                          <MenuItem value="+359">
                            <span className={styles.hiddenSelect}>(</span>
                            +359
                            <span className={styles.hiddenSelect}>) Bulgaria</span>
                          </MenuItem>
                          <MenuItem value="+49">
                            <span className={styles.hiddenSelect}>(</span>
                            +49
                            <span className={styles.hiddenSelect}>) Germany</span>
                          </MenuItem>
                          <MenuItem value="+995">
                            <span className={styles.hiddenSelect}>(</span>
                            +995
                            <span className={styles.hiddenSelect}>) Georgia</span>
                          </MenuItem>
                          <MenuItem value="+48">
                            <span className={styles.hiddenSelect}>(</span>
                            +48
                            <span className={styles.hiddenSelect}>) Poland</span>
                          </MenuItem>
                          <MenuItem value="+380">
                            <span className={styles.hiddenSelect}>(</span>
                            +380
                            <span className={styles.hiddenSelect}>) Ukraine</span>
                          </MenuItem>
                          <MenuItem value="+1">
                            <span className={styles.hiddenSelect}>(</span>
                            +1
                            <span className={styles.hiddenSelect}>) United States</span>
                          </MenuItem>
                          <MenuItem value="+420">
                            <span className={styles.hiddenSelect}>(</span>
                            +420
                            <span className={styles.hiddenSelect}>) Czech Republic</span>
                          </MenuItem>
                          <MenuItem value="+962">
                            <span className={styles.hiddenSelect}>(</span>
                            +962
                            <span className={styles.hiddenSelect}>) Jordan</span>
                          </MenuItem>
                          <MenuItem value="+371">
                            <span className={styles.hiddenSelect}>(</span>
                            +371
                            <span className={styles.hiddenSelect}>) Latvia</span>
                          </MenuItem>
                          <MenuItem value="+372">
                            <span className={styles.hiddenSelect}>(</span>
                            +372
                            <span className={styles.hiddenSelect}>) Estonia</span>
                          </MenuItem>
                          <MenuItem value="+373">
                            <span className={styles.hiddenSelect}>(</span>
                            +373
                            <span className={styles.hiddenSelect}>) Moldova</span>
                          </MenuItem>
                          <MenuItem value="+375">
                            <span className={styles.hiddenSelect}>(</span>
                            +375
                            <span className={styles.hiddenSelect}>) Belarus</span>
                          </MenuItem>
                          <MenuItem value="+374">
                            <span className={styles.hiddenSelect}>(</span>
                            +374
                            <span className={styles.hiddenSelect}>) Armenia</span>
                          </MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={9}>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Phone number"
                        variant="outlined"
                        required
                        fullWidth
                        autoComplete="phone-number"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Email"
                        variant="outlined"
                        type="email"
                        required
                        fullWidth
                        autoComplete="email"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Password"
                        variant="outlined"
                        type="password"
                        required
                        fullWidth
                        autoComplete="current-password"
                      />
                    )}
                  />
                </Grid>
                {errorMessage && (
                  <Grid item xs={12}>
                    <Typography color="error" variant="body2">
                      {errorMessage}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={styles.submitButton}
              >
                Register
              </Button>
            </form>
          ) : (
            <Typography variant="body1">
              You registration is almost done!
              <br />
              Check you email for a confirmation link!
              <br />
              <br />
            </Typography>
          )
        }
        <Grid container className={styles.additionalActions}>
          <Grid item xs={12} sm={6}>
            <Link
              component={RouterLink}
              to="/forgotten"
              style={{ textDecoration: 'none' }}
            >
              Forgot password?
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} className={styles.registerAction}>
            Already have an account?
            &nbsp;
            <Link
              component={RouterLink}
              to="/login"
              style={{ textDecoration: 'none' }}
            >
              Sign in
            </Link>
          </Grid>
        </Grid>
      </div>
      {isLoading && <Loader />}
    </Container>
  );
};

export default RegisterForm;
