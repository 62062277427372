import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';

import Loader from '../Loader';
import { get, put } from '../../shared/api';
import { ORDER_STATUSES } from '../../shared/contants';
import { getDateFormValue, getErrorMessage } from '../../shared/helpers';

import noImage from '../../assets/images/no-image.jpeg';
import styles from './VehicleCard.module.scss';

const VehicleCard = ({ allowTwoColumns = false, data, closeModal, reloadData, canChangeStatus = false }) => {
  const { id, make, model, vin, year, placeOfDelivery, expectedDate } = data || {};
  const [vehicleImage, setVehicleImage] = useState('');
  const [currentStatus, setCurrentStatus] = useState(ORDER_STATUSES.find(status => status.key === data?.status)?.value || 0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    if (vin) {
        get(`/images/get/${id}`)
        .then((images) => {
          if (images && Array.isArray(images)) {
            const imagesData = images.filter(image => !!image);
            if (imagesData.length) {
              setVehicleImage(imagesData[0]?.url || imagesData[0]);
            }
          }
        })
        .catch((error) => {
          addToast(getErrorMessage(error), { appearance: 'error' });
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vin]);

  const handleClose = () => {
    setIsStatusDialogOpen(false);
    setCurrentStatus(ORDER_STATUSES.find(status => status.key === data?.status)?.value || 0);
  };

  const handleOpen = () => {
    setCurrentStatus(ORDER_STATUSES.find(status => status.key === data?.status)?.value || 0);
    setIsStatusDialogOpen(true);
  };

  const handleChange = (event) => {
    setCurrentStatus(+event.target.value);
  };

  const updateStatus = async (event) => {
    event.preventDefault();

    await put('/vehicles', {
      ...data,
      id,
      keysPresent: data.keysPresent === 'true',
      status: +currentStatus,
    })
      .then((response) => {
        if (response) {
          setErrorMessage('');
          addToast('Status updated successfully!', { appearance: 'success' });

          if (reloadData) {
            reloadData();
          }

          handleClose();
        }
      })
      .catch((error) => {
        setErrorMessage(getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const wrapperStyles = [styles.wrapper];
  if (allowTwoColumns) {
    wrapperStyles.push(styles.stretch);
  }

  return (
    <>
      <div className={wrapperStyles.join(' ')}>
        {closeModal && <div className={styles.accentImage} />}
        {allowTwoColumns && (
          <Card className={styles.twoColumns}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                  <Card elevation={2}>
                    <CardMedia
                      component="img"
                      alt={`${make} - ${model}`}
                      title={`${make} - ${model}`}
                      height={250}
                      image={vehicleImage ? vehicleImage : noImage}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <br />
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                      <Typography variant="caption">
                        Order details:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Chip
                        label={ORDER_STATUSES.find(status => status.key === data?.status)?.label || '-'}
                        color="primary"
                        onClick={canChangeStatus ? handleOpen : null}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <br />
                      <Typography gutterBottom variant="h5" component="h2">
                        {make} {model}
                      </Typography>
                      <br />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        VIN:&nbsp;
                        <strong>
                          {
                            vin ? (
                              <a
                                href={`https://www.decodethis.com/${vin}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {vin}
                              </a>
                            ) : '-'
                          }
                        </strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        Year: <strong>{year || '-'}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        Port of destination: <strong>{placeOfDelivery || '-'}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        Expected date:&nbsp;
                        <strong>
                          {expectedDate ? new Date(getDateFormValue(expectedDate)).toLocaleDateString('en-US', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          }) : '-'}
                        </strong>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Grid container justify="space-between" style={{ padding: '0 8px' }}>
                  {closeModal ? (
                    <>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={closeModal}
                      >
                        Close
                      </Button>
                      <Button
                        to={`/orders/${id}`}
                        component={RouterLink}
                        color="primary"
                        variant="contained"
                      >
                        View full details
                      </Button>
                    </>
                  ) : (
                    <Box textAlign="center" width="100%">
                      <Button
                        to={`/orders/${id}`}
                        component={RouterLink}
                        color="primary"
                        variant="contained"
                      >
                        View full details
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
        <Card className={allowTwoColumns ? styles.singleColumn : ''}>
          <CardContent style={{ padding: '16px' }}>
            <Card elevation={3}>
              <CardContent style={{ padding: '16px' }}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography variant="caption">
                      Order details:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Chip
                      label={ORDER_STATUSES.find(status => status.key === data?.status)?.label || '-'}
                      color="primary"
                      onClick={canChangeStatus ? handleOpen : null}
                    />
                  </Grid>
                </Grid>
                <br />
                <Typography gutterBottom variant="h5" component="h2">
                  {make} {model}
                </Typography>
              </CardContent>
            </Card>
          </CardContent>
          <CardContent style={{ padding: '16px' }}>
            <Card elevation={2}>
              <CardMedia
                component="img"
                alt={`${make} - ${model}`}
                title={`${make} - ${model}`}
                height={250}
                image={vehicleImage ? vehicleImage : noImage}
              />
              <CardContent style={{ padding: '16px' }}>
                <Grid container alignContent="space-between" alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">
                      VIN:&nbsp;
                      <strong>
                        {
                          vin ? (
                            <a
                              href={`https://www.decodethis.com/${vin}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {vin}
                            </a>
                          ) : '-'
                        }
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box textAlign="right">
                      <Typography variant="body2">
                        Year: <strong>{year || '-'}</strong>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      Port of destination: <strong>{placeOfDelivery || '-'}</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      Expected date:&nbsp;
                      <strong>
                        {expectedDate ? new Date(getDateFormValue(expectedDate)).toLocaleDateString('en-US', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        }) : '-'}
                      </strong>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </CardContent>
          <CardContent>
            <Grid container justify="space-between">
              {closeModal ? (
                <>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={closeModal}
                  >
                    Close
                  </Button>
                  <Button
                    to={`/orders/${id}`}
                    component={RouterLink}
                    color="primary"
                    variant="contained"
                  >
                    View full details
                  </Button>
                </>
              ) : (
                <Box textAlign="center" width="100%">
                  <Button
                    to={`/orders/${id}`}
                    component={RouterLink}
                    color="primary"
                    target="_blank"
                    variant="contained"
                  >
                    View full details
                  </Button>
                </Box>
              )}
            </Grid>
          </CardContent>
        </Card>
      </div>
      {canChangeStatus && (
        <Dialog
          open={isStatusDialogOpen}
          onClose={handleClose}
          aria-labelledby="vin-search-form"
          maxWidth="xs"
        >
          <form onSubmit={updateStatus}>
            <DialogTitle id="change-status-form">Change status</DialogTitle>
            <DialogContent>
              <FormControl component="fieldset">
                <RadioGroup aria-label="status" name="status" value={currentStatus} onChange={handleChange}>
                  {ORDER_STATUSES.map(({ label, key, value }) => (
                    <FormControlLabel key={key} value={value} control={<Radio />} label={label} />
                  ))}
                </RadioGroup>
              </FormControl>
              <br />
              <br />
              {errorMessage && (
                <Typography color="error" variant="body2">
                  {errorMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
              <Button color="primary" type="submit">Save</Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
      {isLoading && <Loader />}
    </>
  )
};

export default VehicleCard;
