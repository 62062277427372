import jwtDecode from 'jwt-decode';

import { deleteCookie, getJWTToken, JWT_TOKEN_COOKIE_NAME } from '../../shared/web-storage';
import { INITIALIZE_USER, LOG_OUT_USER, SET_USER_FROM_TOKEN } from '../actions/auth.actions';

const initialState = {
  user: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_USER: {
      const userToken = getJWTToken();
      let user = null;

      if (userToken) {
        user = jwtDecode(userToken);
      }

      return {
        ...state,
        user,
      };
    }
    case SET_USER_FROM_TOKEN: {
      const { token } = action.payload;
      const user = jwtDecode(token);

      return {
        ...state,
        user,
      };
    }
    case LOG_OUT_USER: {
      deleteCookie(JWT_TOKEN_COOKIE_NAME);

      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default authReducer;
