import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { COLOR_BACKGROUND } from '../../../../assets/styles/variables';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: COLOR_BACKGROUND,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const AuctionDetails = ({ auction, auctionLocation, stockNumber }) => {
  let stockData = <span>{stockNumber || '-'}</span>;

  if (auction && typeof auction !== 'string') {
    const { name, trackUrl } = auction;
    
    if (name && trackUrl) {
      if (!trackUrl.startsWith("https://")) {
      stockData = (
        <a
          href={`https://${trackUrl}/${stockNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {stockNumber}
        </a>
      );
      }
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Auction details</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              Auction name
            </StyledTableCell>
            <StyledTableCell>
              {auction?.name || '-'}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              Location
            </StyledTableCell>
            <StyledTableCell>
              {auctionLocation || '-'}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              Stock number
            </StyledTableCell>
            <StyledTableCell>
              {stockData}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AuctionDetails;
