import axios from 'axios';

import { API_BASE_URL } from './api.config';
import { deleteCookie, getJWTToken, JWT_TOKEN_COOKIE_NAME } from './web-storage';

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use((config) => {
  const token = getJWTToken();

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

const success = (response) => {
  return response.data;
};

const error = (error) => {
  if (error?.response?.status === 401 && !window.location.href.includes('login')) {
    deleteCookie(JWT_TOKEN_COOKIE_NAME);
    window.location.href = '/login';
  }

  throw error;
};

export const get = (url, config) => {
  return api.get(url, config).then(success).catch(error);
};

export const post = (url, data, config) => {
  return api.post(url, data, config).then(success).catch(error);
};

export const put = (url, data, config) => {
  return api.put(url, data, config).then(success).catch(error);
};

export const del = (url, config) => {
  return api.delete(url, config).then(success).catch(error);
};
