import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Controller, useForm } from 'react-hook-form';
import { TablePagination } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';

import CreatableSelect from 'react-select/creatable';
import {
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  AttachFile,
  Edit, Mail,
  Pageview,
  Search,
  Visibility
} from '@material-ui/icons';

import { MAIN_TITLE } from '../../assets/styles/variables';
import Loader from '../../components/Loader';
import Table from '../../components/Table';
import TransferList from '../../components/TransferList';
import { get, post, put } from '../../shared/api';
import { ORDER_STATUSES, ROLE_ADMIN, ROLE_WAREHOUSE } from '../../shared/contants';
import { getErrorMessage } from '../../shared/helpers';
import { selectUser } from '../../store/selectors/auth.selectors';

import styles from './Orders.module.scss';
// import noImage from '../../assets/images/no-image.jpeg';

const loadPDF = (order) => new Promise((resolve, reject) => {
  get(`/pdf/get/${order.id}`)
    .then((files) => {
      let attachments = {};

      if (files) {
        if (Array.isArray(files)) {
          attachments = files.filter(file => !!file);
        } else {
          attachments = files;
        }
      }

      order.attachments = attachments;

      resolve(order);
    })
    .catch((error) => {
      reject(error);
    });
});

const Orders = () => {
  const [pageNumber, setPage] = useState(0);
  const [pageSize, setRowsPerPage] = useState(20);
  const [total, setTotal] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [areContactsLoading, setAreContactsLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState('');
  const [isUpdateStatusesDialogOpen, setIsUpdateStatusesDialogOpen] = useState(false);
  const [vehiclesToUpdate, setVehiclesToUpdate] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [vehicleToSendEmail, setVehicleToSendEmail] = useState(null);
  const currentUser = useSelector(selectUser);
  const { addToast } = useToasts();
  const { control, handleSubmit, reset } = useForm();
  const {
    control: updateControl,
    handleSubmit: handleUpdateSubmit,
    reset: resetUpdateStatuses,
  } = useForm();

  const setFilteredOrders = (status, searchTerm) => {
    let filteredOrders = [...allOrders];

    // if (status && status !== '') {
    //   filteredOrders = filteredOrders.filter((order) => order?.status === status);
    // }

    if (searchTerm && searchTerm.trim()) {
      setSearchFilter(searchTerm);
    }

      loadOrders(pageNumber, pageSize, statusFilter);
  };

  const loadOrders = (pageNumber, pageSize, statusFilter) => {
    setIsLoading(true);

    let ordersUrl = '/vehicles/my';
    if (currentUser.role === ROLE_ADMIN) {
      ordersUrl = '/vehicles';
    } else if (currentUser.role === ROLE_WAREHOUSE) {
      ordersUrl = `/vehicles/warehouse/${currentUser.email}`;
    }

    if(statusFilter === ''){
      ordersUrl += `?pageNumber=${pageNumber}&pageSize=${pageSize}`
    } else {
      ordersUrl += `?status=${statusFilter}&pageNumber=${pageNumber}&pageSize=${pageSize}`
    }
    if(searchFilter !== ''){
      ordersUrl += `&search=${searchFilter}`
    }

    get(ordersUrl)
      .then(async (ordersData) => {
        await Promise.all(ordersData.vehicleData.map(async (order) => await loadPDF(order)));

        setOrders(ordersData.vehicleData);
        setTotal(ordersData.total)
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadOrdersForBulkUpdate = (statusFilter) => {
    if (currentUser.role === ROLE_ADMIN) {
      let ordersUrl = `/vehicles/bulk`;
      if(statusFilter !== ''){
        ordersUrl += `?status=${statusFilter}`
      }
      get(ordersUrl)
      .then(async (ordersData) => {
        setAllOrders(ordersData);
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
    }
  };

  useEffect(() => {
    loadOrders(pageNumber, pageSize, statusFilter);
    loadContacts();
    loadOrdersForBulkUpdate(statusFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredOrders(statusFilter, searchFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, searchFilter, pageNumber, pageSize]);

  const loadFiles = (vehicleId) => {
    const vehicleIndex = orders.findIndex(vehicle => vehicle.id === vehicleId);

    if (vehicleIndex < 0) {
      addToast('Vehicle was not found!', { appearance: 'error' });
      return;
    }

    const vehicleData = { ...orders[vehicleIndex] };
    vehicleData.areFilesLoading = true;
    const oldOrders = [...orders];
    oldOrders[vehicleIndex] = vehicleData;
    setOrders(oldOrders);

    get(`/pdf/get/${vehicleData.id}`)
      .then((files) => {
        if (files && Array.isArray(files)) {
          vehicleData.attachments = files.filter(file => !!file);
        } else {
          vehicleData.attachments = files;
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        vehicleData.areFilesLoading = false;

        const oldOrders = [...orders];
        oldOrders[vehicleIndex] = vehicleData;
        setOrders(oldOrders);
      });
  };

  const searchVehicles = ({ search }) => {
    setSearchFilter(search);
  };

  const openUpdateStatusesDialog = (event) => {
    event.preventDefault();

    setIsUpdateStatusesDialogOpen(true);
  };

  const closeUpdateStatusesDialog = () => {
    setIsUpdateStatusesDialogOpen(false);
    resetUpdateStatuses({ status: '' });
    setVehiclesToUpdate([]);
  };

  const closeVehiclesFilesDialog = () => {
    setErrorMessage('');
    setSelectedContacts([]);
    setVehicleToSendEmail(null);
  };

  const updateVehicleStatuses = async (data) => {
    setIsLoading(true);

    const { status } = data;

    const formData = {
      status: status,
      vinNumbers: vehiclesToUpdate,
    };

    await put(`/vehicles/status/bulk`, formData)
      .then((response) => {
        if (response) {
          setErrorMessage('');
          addToast(
            'Vehicle statuses updated successfully',
            { appearance: 'success' }
          );

          closeUpdateStatusesDialog();
          reset({ search: '' });
          setSearchFilter('');
          loadOrders(pageNumber, pageSize, statusFilter);
        }
      })
      .catch((error) => {
        setErrorMessage(getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sendVehicleFiles = async (event) => {
    event.preventDefault();

    const emails = selectedContacts.map(({ value }) => value?.trim()).filter(email => !!email);

    const formData = {
      receiverEmails: emails,
      vehicleId: vehicleToSendEmail.id,
    };

    if (emails.length === 0) {
      return;
    }

    setIsLoading(true);

    await post(`/vehicles/sendVehicleFiles`, formData)
      .then((response) => {
        if (response) {
          setErrorMessage('');
          addToast(
            'Email(s) for vehicle files sent successfully',
            { appearance: 'success' }
          );

          closeVehiclesFilesDialog();
          setSelectedContacts([]);
        }
      })
      .catch((error) => {
        setErrorMessage(getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadContacts = async () => {
    await get('/contacts')
      .then((contactsData) => {
        setContacts(contactsData.map(({ email }) => ({ label: email, value: email })));
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      });
  };

  const emailContactsChanged = (newValue) => {
    setSelectedContacts([...newValue]);
  };

  const createNewContact = async (email) => {
    const newEmailContact = { label: email, value: email };

    setAreContactsLoading(true);

    await post('/contacts', { email })
      .then(async (response) => {
        if (response) {
          setErrorMessage('');
          addToast('Email contact created!', { appearance: 'success' });

          await loadContacts();
          setSelectedContacts([...selectedContacts, newEmailContact]);
        }
      })
      .catch((error) => {
        setErrorMessage(getErrorMessage(error));
      })
      .finally(() => {
        setAreContactsLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    loadOrders(newPage, pageSize, statusFilter)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleFiltersAndOrders(statusValue) {
    setPage(0)
    setStatusFilter(statusValue);
    loadOrders(pageNumber, pageSize, statusValue);
    loadOrdersForBulkUpdate(statusValue);
  }

  const getStatusColor = (status) => {
    switch (status) {
      case 'InTransit':
        return '#FFC72C'; // Light blue color
      case 'LoadedInTheContainer':
        return '#0066CC'; // Amber color
      case 'Unloaded':
        return '#D00000'; // Deep orange color
      case 'LoadedOnTheTruck':
        return '#E6A100'; // Lime color
      case 'FinalDestination':
        return '#35A03C'; // Green color
      default:
        return '#000000'; // Default color
    }
  };
  
  return (
    <>
      <Helmet>
        <title>Vehicles | {MAIN_TITLE}</title>
      </Helmet>
      {isLoading && <Loader />}
      <TableHead>
        <div style={{ padding: '10px' }}>
            <TablePagination
              component="div"
              style={{
                backgroundColor: '#ffff', color: '#3f51b5', borderRadius: '10px',
              }}
              labelRowsPerPage={<InputLabel>Rows per page:</InputLabel>}
              rowsPerPageOptions={[10,20,30,50]}
              rowsPerPage={pageSize}
              count={total}
              page={pageNumber}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
      </TableHead>
      <Table
        columns={[
          {
            title: 'Status',
            field: 'status',
            searchable: false,
            render: order => (
              <Chip
                label={ORDER_STATUSES.find((status) => status.key === order?.status)?.label || order.status || '-'}
                style={{ backgroundColor: getStatusColor(order?.status), color: 'white' }}
              />
            )
          },
          // {
          //   title: 'Preview',
          //   field: 'previewUrl',
          //   sorting: false,
          //   searchable: false,
          //   render: order => (
          //     <img
          //       className={styles.imagePreview}
          //       src={order?.previewUrl || noImage}
          //       alt={`${order.year} ${order.make} ${order.model}`}
          //     />
          //   )
          // },
          (currentUser.role === ROLE_ADMIN ? {
            title: 'User',
            field: 'firstLastName',
            render: order => (
              <div>
                <p>
                  {order.firstLastName ? (
                    <a href={`mailto:${order.userName}`}>{order.firstLastName}</a>) : 'Not specified'}
                </p>
                <br />
                {(currentUser?.role === ROLE_ADMIN && order.clearanceCompany) && <p>{order.clearanceCompany}</p>}
              </div>
            )
          } : null),
          {
            title: 'Vehicle', field: 'year', render: order => (
              <>
                {order.year}&nbsp;{order.make}&nbsp;{order.model}
                <br />
                <br />
                {
                  order.vin ? (
                    <Link
                      to={`/orders/${order.id}`}
                      component={RouterLink}
                      target="_blank"
                      color="primary"
                      area-label="view"
                    >
                      {order.vin}
                    </Link>
                    // <a
                    //   href={`https://www.decodethis.com/${order.vin}`}
                    //   target="_blank"
                    //   rel="noopener noreferrer"
                    // >
                    //   {order.vin}
                    // </a>
                  ) : '-'
                }
              </>
            )
          },
          {
            title: 'Origin', field: 'auctionName.name', render: order => (
              <div>
                {order.auctionName?.name && (
                  <p>{order.auctionName.name}</p>
                )}
                <br />
                {order.auctionLocation?.name && (
                  <p>/{order.auctionLocation.name}/</p>
                )}
              </div>
            )
          },
          {
            title: 'Unloading Dates', field: 'expectedDates', render: order => (
              <div>
                <p>
                  <strong style={{ fontWeight: 600 }}>Truck:&nbsp;</strong>
                  {order.truck?.expectedDate || 'N/A'}
                </p>
                <br />
                <p>
                  <strong style={{ fontWeight: 600 }}>
                    Cont.:&nbsp;
                  </strong>
                  {order.container?.expectedDate || 'N/A'}
                </p>
              </div>
            )
          },
          {
            title: 'Destination', field: 'expectedDate', render: order => (
              <div>
                <p>
                  <strong style={{ fontWeight: 600 }}>POD:&nbsp;</strong>
                  {order.placeOfDelivery || '-'}
                </p>
                <br />
                <p>
                  <strong style={{ fontWeight: 600 }}>
                    ETA:&nbsp;
                  </strong>
                  {order.expectedDate || 'N/A'}
                </p>
              </div>
            )
          },
          {
            title: 'Documents',
            field: 'documents',
            sorting: false,
            searchable: false,
            render: order => order.attachments ? (
              <div style={{ minWidth: '130px' }}>
                {Array.isArray(order.attachments) ? (
                  <div>
                    Files:&nbsp;{order.attachments.length ? order.attachments.map((fileData, index) => (
                      <IconButton
                        key={`file-${index}`}
                        component="a"
                        href={fileData?.url || fileData}
                        rel="noopener noreferrer"
                        target="_blank"
                        color="primary"
                        area-label="download"
                        size="small"
                        title={fileData.title || fileData}
                      >
                        <AttachFile fontSize="small" />
                      </IconButton>
                    )) : (
                      <Typography color="error" variant="body1">
                        N/A
                      </Typography>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className={styles.documentRow}>
                      <span className={styles.documentTitle}>Bill of lading:&nbsp;</span>
                      {order.attachments?.billOfLading?.length
                        ? order.attachments.billOfLading.map((fileData, index) => (
                          <IconButton
                            key={`bol-${index}`}
                            component="a"
                            href={fileData?.url || fileData}
                            rel="noopener noreferrer"
                            target="_blank"
                            color="primary"
                            area-label="download"
                            size="small"
                            title={fileData.title || fileData}
                          >
                            <AttachFile fontSize="small" />
                          </IconButton>
                        ))
                        : (
                          <Typography color="error" variant="body1">
                            N/A
                          </Typography>
                        )
                      }
                    </div>
                    <div className={styles.documentRow}>
                      <span className={styles.documentTitle}>Bill of sales:&nbsp;</span>
                      {order.attachments?.billOfSale?.length
                        ? order.attachments.billOfSale.map((fileData, index) => (
                          <IconButton
                            key={`bos-${index}`}
                            component="a"
                            href={fileData?.url || fileData}
                            rel="noopener noreferrer"
                            target="_blank"
                            color="primary"
                            area-label="download"
                            size="small"
                            title={fileData.title || fileData}
                          >
                            <AttachFile fontSize="small" />
                          </IconButton>
                        ))
                        : (
                          <Typography color="error" variant="body1">
                            N/A
                          </Typography>
                        )
                      }
                    </div>
                    <div className={styles.documentRow}>
                      <span className={styles.documentTitle}>Vehicle title:&nbsp;</span>
                      {order.attachments?.vehicleTitles?.length
                        ? order.attachments.vehicleTitles.map((fileData, index) => (
                          <IconButton
                            key={`vt-${index}`}
                            component="a"
                            href={fileData?.url || fileData}
                            rel="noopener noreferrer"
                            target="_blank"
                            color="primary"
                            area-label="download"
                            size="small"
                            title={fileData.title || fileData}
                          >
                            <AttachFile fontSize="small" />
                          </IconButton>
                        ))
                        : (
                          <Typography color="error" variant="body1">
                            N/A
                          </Typography>
                        )
                      }
                    </div>
                    <div className={styles.documentRow}>
                      <span className={styles.documentTitle}>CMR:&nbsp;</span>
                      {order.attachments?.cmr?.length
                        ? order.attachments.cmr.map((fileData, index) => (
                          <IconButton
                            key={`vt-${index}`}
                            component="a"
                            href={fileData?.url || fileData}
                            rel="noopener noreferrer"
                            target="_blank"
                            color="primary"
                            area-label="download"
                            size="small"
                            title={fileData.title || fileData}
                          >
                            <AttachFile fontSize="small" />
                          </IconButton>
                        ))
                        : (
                          <Typography color="error" variant="body1">
                            N/A
                          </Typography>
                        )
                      }
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div style={{ minWidth: '130px' }}>
                <Button
                  component="button"
                  color="primary"
                  variant="outlined"
                  size="small"
                  disabled={order.areFilesLoading}
                  onClick={() => loadFiles(order.id)}
                  style={{ marginRight: '5px' }}
                >
                  <Pageview />&nbsp;Load files
                </Button>
              </div>
            )
          },
          {
            searchable: false,
            sorting: false,
            render: order => (
              <>
                <IconButton
                  to={`/orders/${order.id}`}
                  component={RouterLink}
                  target="_blank"
                  color="primary"
                  area-label="view"
                >
                  <Visibility />
                </IconButton>
                {(
                  currentUser &&
                  (currentUser.role === ROLE_ADMIN
                    || currentUser.role === ROLE_WAREHOUSE
                  )) && (
                    <IconButton
                      to={`/orders/${order.id}/edit`}
                      component={RouterLink}
                      color="primary"
                      area-label="edit"
                    >
                      <Edit />
                    </IconButton>
                  )}
                {(
                  currentUser &&
                  currentUser.role === ROLE_ADMIN
                ) && (
                    <IconButton
                      component="button"
                      color="primary"
                      area-label="sentMail"
                      onClick={() => setVehicleToSendEmail(order)}
                    >
                      <Mail />
                    </IconButton>
                  )}
              </>
            ),
          }
        ].filter((column) => !!column)}
        data={orders}
        options={
          {
            search: false,
            paging: false,
            maxBodyHeight: 'calc(100vh - 225px)',
          }
        }
        components={{
          Toolbar: props => (
            <div style={{ padding: '10px 15px' }}>
              <Grid container alignItems="center">
                <Grid item xs={6} lg={5}>
                  <Typography variant="h5">
                    Vehicles
                  </Typography>
                </Grid>
                <Grid item xs={6} lg={7}>
                  <Grid container justify="flex-end">
                    {(
                      currentUser &&
                      (currentUser.role === ROLE_ADMIN
                        || currentUser.role === ROLE_WAREHOUSE
                      )) && (
                        <Button
                          style={{ marginRight: '10px' }}
                          onClick={openUpdateStatusesDialog}
                          color="primary"
                          variant="outlined"
                        >
                          Update statuses
                        </Button>
                      )}
                    <form onSubmit={handleSubmit(searchVehicles)}>
                      <Controller
                        name="search"
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className={styles.searchField}
                            label="Search"
                            variant="outlined"
                            placeholder="Search by model, make, year, etc..."
                            autoComplete="email"
                            required={false}
                            size="small"
                          />
                        )}
                      />
                      <IconButton color="primary" size="small" style={{ margin: '5px 0 0 8px' }} type="submit">
                        <Search />
                      </IconButton>
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <ButtonGroup disableElevation variant="contained" fullWidth>
                <Button
                  style={{
                    color: statusFilter === '' ? '#ffffff' : null,
                    backgroundColor: statusFilter === '' ? '#3f51b5' : null
                  }}
                  onClick={() => handleFiltersAndOrders('')}
                >
                  All
                </Button>
                {ORDER_STATUSES.map(({ key, label, value }) => (
                  <Button
                    key={`status-${value}`}
                    style={{
                      color: statusFilter === value ? '#ffffff' : null,
                      backgroundColor: statusFilter === value ? '#3f51b5' : null
                    }}
                    onClick={() => handleFiltersAndOrders(value)}
                  >
                    {label}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
          )
        }}
      />
      <Dialog
        open={isUpdateStatusesDialogOpen}
        onClose={closeUpdateStatusesDialog}
        fullWidth
        maxWidth="md"
      >
        <form onSubmit={handleUpdateSubmit(updateVehicleStatuses)}>
          <DialogTitle>Update multiple vehicles statuses</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id="status" variant="outlined" required>New status</InputLabel>
                  <Controller
                    name="status"
                    control={updateControl}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="status"
                        label="New status"
                        variant="outlined"
                        required
                        fullWidth
                      >
                        {ORDER_STATUSES.map(({ key, label, value }) => (
                          <MenuItem key={`status-${key}`} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TransferList
                  leftLabel="Vehicles"
                  rightLabel="Vehicles to update"
                  initialLeftItems={allOrders.map(v => v.vin)}
                  initialRightItems={[]}
                  handleChanged={(vinNumbers) => setVehiclesToUpdate(vinNumbers)}
                />
              </Grid>
              {errorMessage && (
                <Grid item xs={12}>
                  <Typography color="error" variant="body2">
                    {errorMessage}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              color="primary"
              disabled={vehiclesToUpdate.length === 0}
            >
              Save
            </Button>
            <Button onClick={closeUpdateStatusesDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={!!vehicleToSendEmail}
        onClose={closeVehiclesFilesDialog}
        fullWidth
        maxWidth="md"
      >
        <form onSubmit={sendVehicleFiles}>
          <DialogTitle>Send vehicle information</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div style={{ minHeight: '200px' }}>
                  <CreatableSelect
                    isMulti
                    isDisabled={areContactsLoading}
                    isLoading={areContactsLoading}
                    options={contacts}
                    value={selectedContacts}
                    onChange={emailContactsChanged}
                    onCreateOption={createNewContact}
                  />
                </div>
              </Grid>
              {errorMessage && (
                <Grid item xs={12}>
                  <Typography color="error" variant="body2">
                    {errorMessage}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="submit" color="primary">
              Send
            </Button>
            <Button onClick={closeVehiclesFilesDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default Orders;
