import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import {
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';

import { MAIN_TITLE } from '../../assets/styles/variables';
import Loader from '../../components/Loader';
import RouterPrompt from '../../components/RouterPrompt';
import { get, post } from '../../shared/api';
import { getErrorMessage } from '../../shared/helpers';
import { selectUser } from '../../store/selectors/auth.selectors';

import styles from '../Login/components/LoginForm.module.scss';

const VehicleRequest = () => {
  const { control, formState, handleSubmit, reset, setValue, watch } = useForm();
  const [warehouses, setWarehouses] = useState([]);
  const [terminals, setTerminals] = useState([]);
  const [auctionsData, setAuctionsData] = useState([]);
  const [auctionLocations, setAuctionLocations] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateSuccessful, setIsCreateSuccessful] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoadingWarehouses, setIsLoadingWarehouses] = useState(false);
  const history = useHistory();
  const { addToast } = useToasts();
  const { isDirty } = formState;
  const auctionId = watch('auctionName');
  const currentUser = useSelector(selectUser);

  useEffect(() => {
    loadAuctionsData();
    loadTerminals();
    loadWarehouses();

    return () => {
      reset();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auctionsData.length === 0) {
      return;
    }

    if (auctionId) {
      const currentAuction = auctionsData.find(auction => auction.id === auctionId);

      if (currentAuction && currentAuction.locations) {
        setAuctionLocations(currentAuction.locations);
      } else {
        setValue('auctionLocation', null);
        setAuctionLocations([]);
      }
    } else {
      setValue('auctionLocation', null);
      setAuctionLocations([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionId, auctionsData]);

  const loadAuctionsData = () => {
    get('/auctions')
      .then((data) => {
        if (Array.isArray(data)) {
          setAuctionsData(data);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadTerminals = () => {
    get('/terminals')
      .then((data) => {
        if (Array.isArray(data)) {
          setTerminals(data);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadWarehouses = () => {
    setIsLoadingWarehouses(true);

    get('/identity/warehouses/all')
      .then((warehousesData) => {
        if (Array.isArray(warehousesData)) {
          setWarehouses(warehousesData);
        }
      })
      .catch((error) => {
        addToast(getErrorMessage(error), { appearance: 'error' });
      })
      .finally(() => {
        setIsLoadingWarehouses(false);
      });
  }


  const onSubmit = async (data) => {
    setIsLoading(true);
    setErrorMessage('');

    await post('/vehicles/request', {
      ...data,
      username: currentUser ? currentUser.email : '',
      year: data.year.toString(),
      keysPresent: data.keysPresent === 'true',
    })
      .then((response) => {
        if (response) {
          setErrorMessage('');
          addToast('Request placed successfully!', { appearance: 'success' });
          setIsSuccessful(true);
          setIsCreateSuccessful(true);

          history.push('/orders');
        }
      })
      .catch((error) => {
        setErrorMessage(getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Request a vehicle | {MAIN_TITLE}</title>
      </Helmet>
      <RouterPrompt
        when={isCreateSuccessful || (isDirty && !isSuccessful)}
        title="Leave request a vehicle form?"
      />
      <Container component="main">
        <Card>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Request a vehicle
            </Typography>
            <Divider />
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} className="auction-data">
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="heading">
                      <Typography variant="h6" gutterBottom align="center">
                        VIN
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="vin"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="VIN"
                            variant="outlined"
                            fullWidth
                            required
                          />
                        )}
                      />
                      <br />
                      <br />
                    </Grid>
                    <Grid item xs={12} className="auction-info">
                      <Typography variant="h6" gutterBottom align="center">
                        Auction information
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="stockNumber"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Stock number"
                            variant="outlined"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="auction-name" variant="outlined" required>Auction name</InputLabel>
                        <Controller
                          name="auctionName"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              labelId="auction-name"
                              label="Auction name"
                              variant="outlined"
                              fullWidth
                              required
                            >
                              {auctionsData.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>{name}</MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="auction-location" variant="outlined" required>Auction location</InputLabel>
                        <Controller
                          name="auctionLocation"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              labelId="auction-location"
                              label="Auction location"
                              variant="outlined"
                              disabled={!auctionId}
                              fullWidth
                              required
                            >
                              {auctionLocations.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>{name}</MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="additional-info">
                      <Typography variant="h6" gutterBottom align="center">
                        Additional information
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="status" variant="outlined" required>Status</InputLabel>
                        <Select
                          disabled={true}
                          labelId="status"
                          label="Status"
                          variant="outlined"
                          required
                          fullWidth
                          value="7"
                        >
                          <MenuItem key="status-new" value="7">New</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="make"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Make"
                            variant="outlined"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="model"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Model"
                            variant="outlined"
                            fullWidth
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="year"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Year"
                            required
                            variant="outlined"
                            type="number"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="keys-present" variant="outlined" required>Keys present</InputLabel>
                        <Controller
                          name="keysPresent"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              labelId="keys-present"
                              label="Keys present"
                              variant="outlined"
                              required
                              fullWidth
                            >
                              <MenuItem value="true">Yes</MenuItem>
                              <MenuItem value="false">No</MenuItem>
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="user-attachment">
                      <Typography variant="h6" gutterBottom align="center">
                        Warehouse
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="warehouse" variant="outlined" required>Warehouse</InputLabel>
                        <Controller
                          name="warehouse"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              labelId="warehouse"
                              label="Warehouse"
                              variant="outlined"
                              fullWidth
                              required
                            >
                              {warehouses.map(({ email, firstName, lastName }) => (
                                <MenuItem key={email} value={email}>{firstName} {lastName}</MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="shipping-details">
                      <Typography variant="h6" gutterBottom align="center">
                        Shipping details
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="placeOfDelivery"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Port of destination"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="terminal" variant="outlined" required>
                          Loading terminal
                        </InputLabel>
                        <Controller
                          name="terminal"
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              labelId="terminal"
                              label="Loading terminal"
                              variant="outlined"
                              fullWidth
                              required
                            >
                              {terminals.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>{name}</MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} className="notes">
                  <Grid container spacing={2}>
                    <Grid item xs={12} className="additional-notes">
                      <Typography variant="h6" gutterBottom align="center">
                        Notes
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Controller
                        name="note"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Additional notes"
                            variant="outlined"
                            multiline
                            rows={5}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {errorMessage && (
                  <Grid item xs={12}>
                    <Typography color="error" variant="body2">
                      {errorMessage}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid container justify="flex-end" style={{ paddingTop: '10px' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      className={styles.submitButton}
                    >
                      Request vehicle
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>
      {(isLoading || isLoadingWarehouses) && <Loader />}
      <br />
    </>
  );
};

export default VehicleRequest;
