import React from 'react';

export const getErrorMessage = (error) => {
  const errorData = error?.response?.data?.errors
    ? error.response.data.errors[0]
    : typeof error?.response?.data === 'string' ? error?.response?.data : null;

  let errorText = 'Something went wrong! Try again later.';

  if (errorData) {
    if (typeof errorData === 'string') {
      errorText = errorData;
    } else if (errorData.message) {
      errorText = errorData.message;
    }
  }

  return errorText;
};


export const generateUrlElement = (url) => {
  let urlElement = <span>-</span>;

  if (url) {
    let urlAddress = url;

    if (!url.includes('http')) {
      urlAddress = `http://${urlAddress}`;
    }

    urlElement = (
      <a
        href={urlAddress}
        target="_blank"
        rel="noopener noreferrer"
      >
        {url}
      </a>
    );
  }

  return urlElement;
};

export const getDateFormValue = (dateInput) => {
  let date = '';

  if (dateInput && dateInput.length >= 10) {
    const dateString = dateInput.slice(0, 10);
    const dateValue = new Date(dateString);

    if (dateValue !== 'Invalid Date') {
      date = dateValue.toISOString().split('T')[0];
    }
  }

  return date;
};

export const notIn = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

export const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

export const union = (a, b) => {
  return [...a, ...notIn(b, a)];
};
