import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({
  authenticationPath,
  component,
  isAllowed = true,
  isAuthenticated = false,
  redirectedPath,
  ...rest
}) => {
  let redirectPath = '';
  if (!isAuthenticated) {
    redirectPath = authenticationPath;
  }

  if (redirectedPath && isAuthenticated && !isAllowed) {
    redirectPath = redirectedPath;
  }

  if (redirectPath) {
    const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
    return <Route {...rest} component={renderComponent} />;
  } else {
    return <Route {...rest} component={component} />;
  }
};

export default PrivateRoute;
