import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { COLOR_BACKGROUND } from '../../../../assets/styles/variables';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: COLOR_BACKGROUND,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const CarDetails = ({ vin, make, model, year, keysPresent }) => (
  <TableContainer component={Paper}>
    <Table aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell>Car details</StyledTableCell>
          <StyledTableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row">
            VIN
          </StyledTableCell>
          <StyledTableCell>
            {
              vin ? (
                <a
                  href={`https://www.decodethis.com/${vin}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {vin}
                </a>
              ) : '-'
            }
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row">
            Make
          </StyledTableCell>
          <StyledTableCell>
            {make || '-'}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row">
            Model
          </StyledTableCell>
          <StyledTableCell>
            {model || '-'}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row">
            Year
          </StyledTableCell>
          <StyledTableCell>
            {year || '-'}
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell component="th" scope="row">
            Keys present
          </StyledTableCell>
          <StyledTableCell>
            {keysPresent ? 'Yes' : 'No'}
          </StyledTableCell>
        </StyledTableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export default CarDetails;
