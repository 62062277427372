import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';

import VehicleCard from '../VehicleCard';
import Loader from '../Loader';
import { get } from '../../shared/api';
import { getErrorMessage } from '../../shared/helpers';

import styles from './VimModal.module.scss';

const VimModal = ({ open = false, handleClose }) => {
  const { control, handleSubmit, reset } = useForm();
  const [vehicle, setVehicle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { addToast } = useToasts();

  const checkByVim = async ({ vin }) => {
    setIsLoading(true);
    setErrorMessage('');

    if (vin && vin.trim()) {
      await get(`/vehicles/vin/${vin.trim()}`)
        .then((data) => {
          setVehicle(data);
        })
        .catch((error) => {
          addToast(getErrorMessage(error), { appearance: 'error' });
          setErrorMessage(getErrorMessage(error));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const closeModal = () => {
    handleClose();
    reset({ vin: '' });
    setErrorMessage('');
  };

  const handleVehicleClose = () => {
    setVehicle(null);
    closeModal();
  };

  useEffect(() => {
    return () => {
      setErrorMessage('');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog
        open={open && !vehicle}
        onClose={closeModal}
        aria-labelledby="vin-search-form"
        maxWidth="xs"
      >
        <form onSubmit={handleSubmit(checkByVim)}>
          <DialogTitle id="vin-search-form">Track your order</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter you VIN
            </DialogContentText>
            <Controller
              name="vin"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="VIN"
                  required
                  fullWidth
                />
              )}
            />
            <Typography variant="caption" gutterBottom align="center">
              You can search by full VIN, last six or eight characters.
            </Typography>
            <br />
            <br />
            {errorMessage && (
              <Typography color="error" variant="body2">
                {errorMessage}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>Close</Button>
            <Button color="primary" type="submit">Search</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={!!vehicle}
        onClose={handleVehicleClose}
        aria-labelledby="vehicle-card"
        classes={{ paper: styles.dialog }}
      >
        <VehicleCard allowTwoColumns data={vehicle} closeModal={handleVehicleClose} />
      </Dialog>
      {isLoading && <Loader />}
    </>
  );
}

export default VimModal;
